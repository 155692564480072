

.container h1 {
  color: #000000;
  font-size: 20px;
  font-family: "poppins";
  font-weight: bold;
  margin-top: 1.5rem;
}

.container p {
  font-size: 16px;
  font-family: "poppins";
  font-weight: 300;
  color: #000000;
}

.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  /* margin-top: 30px; */
  /* gap: 1rem; */
  /* margin-bottom: 10%; */
  /* margin-top: 50px; */
  /* border: 2px solid blue; */
 
}

footer > *:nth-child(1) {
  flex: 1 1 30%;
}

footer > *:nth-child(2) {
  flex: 1 1 70%;
}



.logo {
  display: flex;
  flex-direction: row;
  width: 100%;

  gap: 1em;
}

.logo span {
  font-size: 12px;
  font-family: "poppins";
  font-weight: 400;
  color: #131212;
}
@media screen and (max-width: 700px) {
  .footer h2 {
  color: #131212;
  font-size: 14px;
  font-family:poppins;
  font-weight: 500;
}
 .container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    align-items: center;
}
  .footer {
  display: flex;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    border-top: 1px solid #dfdfdf;
    gap: 10px;
    align-items: center;
}
  .events_image_container {
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 700px) {

  .footer h2 {
  color: #131212;
  font-size: 14px;
  font-family: "poppins";
  font-weight: 550;
}
  .container {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-bottom: 20px;
  /* margin-top: 20px; */
  width: 100%;
  height: 97%;
  /* border: 2px solid blue; */
}
  .footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
    margin-bottom: 10px;
    margin-top: 38%;
  }
}
