.age-group-chart {
  
    width: 486px;
    height: 397px;
    padding: 32px; /* Example padding */
    border-radius: 8px;
  background: var(--Neutral-0, #FFF);
  margin-right: 24px;
  
  }
  
  .chart-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .bar-chart {
    width: 100%;
    height: 100px;
  }
  
  /* .bar-chart .y-axis text {
    text-anchor: end;
  } */
  
  .bar-chart .y-axis line {
    stroke: #ddd;
    stroke-width: 0.5;
  }
  
  .legend {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  
  .legend-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .legend-label {
    font-size: 14px;
    color: #333;
  }
  