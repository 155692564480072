/* .header {
  width: 100%;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
} */

.logo {
  margin-left: 0.2rem;
  height: 3.8rem;
  width: 5.5rem;
}
.logo img {
  width: 128px;
  height: 67px;
}

.user {
  margin-right: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.user img {
  width: 32px;
  height: 32px;
  margin-right: 0.7rem;
  border-radius: 50%;
}
.user span {
  font-size: 16px;
  color: #696969;
  font-family: "poppins";
  margin-right: 1rem;
  font-weight: 800;
}
.user .logout {
  width: 24px;
  height: 24px;
}

.logout-btn {
  border: 1px solid;
}

@media (max-width: 800px) {
  /* .user {
    margin-right: 1rem;
  } */
  /* .user span {
    font-size: 13px;
    margin-right: 0rem;
    font-weight: 600;
  } */

  .logo {
    margin-left: 1rem;
    height: 3rem;
    width: 4rem;
  }
  .logo img {
    width: 4rem;
    height: 3rem;
  }

  /* .header {
    width: 100%;
    display: flex;
    height: auto;
    padding: 1rem 1rem 0 1rem;
  } */
}
@media screen and (max-width: 700px) {
    /* .user {
    margin-right: -22px;
  } */
  .logo {
    margin-left: 0.2rem;
    height: 100%;
    width: 100%;
  }
  /* .header {
   position: fixed;
    width: 100vw;
    height: 5vh;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  justify-content: flex-end;
  } */
}
@media screen and (min-width: 700px) {
  .logo {
    margin-left: 2.2rem;
    height: 3.8rem;
    width: 5.5rem;
  }
  .header {
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
  }
}
