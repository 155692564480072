.ant-drawer-bottom > .ant-drawer-content-wrapper {
    top: 16em;
    right: -1em;
    bottom: 0;
    width: 30%;
    height: 45vh;
    left: 14em;
}

.ant-drawer-content {
    width: 80%;
}
.ant-input:hover {
    border-color: whitesmoke;
    border-right-width: 1px;
}
.SearchCompo_inputSearch {
  align-items: center;
  background: #fff;
  background-image: url(https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-512.png);
  background-position: 10px calc(50% + 1px);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: 1px solid #bebebe;
  border-radius: 5px;
  
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-size: 13px;
  line-height: 1.5;
  padding: 0.5em 1em 0.5em 2.5em;
  width: 100%;

  &:focus {
    border-color: #bebebe;
    outline: none; 
    box-shadow: 0 0 5px #bebebe; 
  }
}
