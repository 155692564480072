/* .ChatImageProfile {
  border-radius: 4px;
  width: 2.5vw;
  height: 5vh;
  margin-top: 11px;
} */
.ChatUserName {
  font: normal normal bold 14px/22px Poppins;
  display: flex;
}
.ChatTime {
  font-size: 12px;
  color: #787878;
}
.ChatText {
  font-size: 14px;
  font-family: sans-serif;
  width: 35vw;
}

.ChatUserAndTime {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.chatData {
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
  padding: 5px;
}
/* .SingleChat {
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  gap: 10px;
} */
@media screen and (max-width: 700px) {
  .FileSharediv {
    display: table;
    align-items: center;
    gap: 3px;
    width: fit-content;
    overflow: auto;
  }
  .FileRow {
    margin-bottom: 3px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 3px;
    border: 1px solid #e0dfdf;
    height: auto;
  }
  .ChatImageProfile {
    border-radius: 50%;
    margin: initial;
    width: 35px;
    height: 35px;
  }
  .SingleChat {
    display: flex;
    padding: 10px 16px;
    gap: 10px;
    background-color: white;
    margin: 20px;
    border-radius: 5px;
    width: fit-content;
    border: 1px solid #dfdfdf;
  }
}
@media screen and (min-width: 700px) {
  .FileSharediv {
    display: table;
    align-items: center;
    gap: 3px;
    width: fit-content;
    overflow: auto;
  }
  .FileRow {
    margin-bottom: 3px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 3px;
    border: 1px solid #e0dfdf;
    height: auto;
  }

  .ChatImageProfile {
    border-radius: 4px;
    width: 2.5vw;
    height: 5vh;
    margin-top: 11px;
  }
  .SingleChat {
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
    gap: 10px;
  }
}
