.winnerModalMainRow{

   font-style: italic;
   height: 100%;
   /* width: 700px; */
   margin: auto;
   justify-content: center;
   /* border: 2px solid blue; */

}

.winnerModalYearWinner{
  color: white;
  font-style: italic;
  font-weight: bolder;
  font-size:45px ;
  width: 100%;
  justify-content: center;
  margin: auto;
  /* border: 2px solid orange; */
}
.Winner {
  border-radius: 28px;
}
.Power {
  border-radius: 28px;
  opacity: 70%;
}
.Power:hover {
  opacity: 100%;
  transform: scale(1.02);
  transition: all ease 0.3s;
}
.Champ {
  border-radius: 28px;
  height: 400px;
  opacity: 70%;
}
.Champ:hover {
  opacity: 100%;
  transform: scale(1.02);
  transition: all ease 0.3s;
}
.closeModal{
   opacity: 40%;
}
.closeModal:hover{
  opacity: 100%;
  transform: scale(1.02);
  transition: all ease 0.3s;
}
.Winner2 {
  border-radius: 28px;
  width: 400px;
  opacity: 70%;
}

.Winner2:hover {
  opacity: 100%;
  transform: scale(1.02);
  transition: all ease 0.3s;
}


@media screen and (min-width: 700px){

  .winnerModalMainRow{
    font-style: italic;
    height: 100%;
    width: 30%  ;
    margin:auto;
    justify-content: center;
    /* border: 2px solid yellow; */
  font-size: 1rem;
  }
  .Winner {
    border-radius: 20px;
  }

  .Winner2 {
    border-radius: 20px;
    width: 200px;
    opacity: 50%;
  }

  .Winner2:hover {
    opacity: 60%;
    transform: scale(1.02);
    transition: all ease 0.3s;
  }
  .winnerModalYearWinner{
    color: white;
    font-size: 2rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  /* .colWise{
  } */
}

@media screen and (max-width: 600px) {
  .winnerModalMainRow{
    font-style: italic;
    height: 100%;
    width:100%  ;
    margin: auto;
    justify-content: center;

  }
  .winnerModalYearWinner{
    color: white;
    font-size: 1rem;
    padding-left: 5rem;
    display: flex;
    justify-content: space-between;
  }
  .colWise{
    display: flex;
    flex-direction: column;
    width: 30%;
  
  }

  .mobileImg{
    margin: 0px;
    margin-left: -9px !important
  }
  .winnerLabel{
    padding-left: 3rem !important;
  }
 
}
