/* .main {
  width: 100%;
  display: flex;
  background-color: #ffffff;
  height: 88vh;
  overflow-y: auto;
} */


@media screen and (max-width: 700px) {
.main > *:nth-child(2) {
       display: flex;
    width: 100vw;
    position: absolute;
    height: 100vh;
    margin-top: 41px;
}
  .main {
    display: flex;
    width: 100%;
    /* height: 100vh; */
    overflow-y: auto;
  }

  .closed {
    height: fit-content;
  }
  .SidebardivMob {
    display: flex;
    z-index: 2;
    width: 0px;
    height: 100%;
    backdrop-filter: blur(10px);
    position: fixed;
  }
  .brigosha {
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 0rem;
    margin-top: 50px;
    text-align: center;
    left: 40px;
  }
}
@media screen and (min-width: 700px) {
  .main > *:nth-child(1) {
  flex: 1 1 15%;
}

.main > *:nth-child(2) {
  flex: 1 1 85%;
}
  .main {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    height: 88vh;
    overflow-y: auto;
  }
  .Sidebardiv {
    width: 15vw;
    display: flex;
    z-index: 2;
  }
}
