/* src/ParticipationRate.css */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.participation-container {
  width: 576px;
  height: 352px;
  padding: 32px; /* Example padding */
  border-radius: 8px;
background: var(--Neutral-0, #FFF);
margin-top: 24px;
  }
  
  .charts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .donut-chart,
  .bar-chart {
    flex: 1;
    margin: 10px;
  }

  .ParticipationPie{
    width: 208px;
    height: 256px;
  }
.LabelListClass{
  font-family: 'Roboto';
}
