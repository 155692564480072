.mainDiv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 16px;
  gap: 6px;
  /* height: 40px; */
  box-sizing: border-box;
}
.mainBtn{
        border: none;
        font: normal 400 14px/18px Poppins;
        color: inherit;
        background-color: transparent;
       cursor: inherit;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        text-align: center;
        text-decoration: none;
        /* margin:8px; */
      }
.disabled{
        cursor: not-allowed;
        opacity: 0.4 !important;
}