.dashboard {
   
  padding-top: 24px;
  padding-left: 24px;
  }
  
 
  .chart-column {
    display: inline-flex;
    flex-direction: row;
    height: 100%;
  }
  