.house-details-container {
  display: flex;
  justify-content: space-between;
  width: 24%;
}
.house-details {
  width: 24rem;
  height: 100px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000014;
}
.house-details-content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
}
.house-details-content-heading {
  font-size: 18px;
  font-family: "poppins";
  font-weight: 500;
  text-transform: uppercase;
  color: black;
}
.house-team-count {
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 900px) {
  .house-details-container {
    justify-content: center;
    width: 100%;
  }

  .house-details {
    width: 90%;
    height: 120px;
    margin-top: 18px;
  }

 

   .sliderHouse{
    /* border: 2px solid red; */
     width:100%;
    /* display:flex;  */
    margin-bottom:2rem;
    padding:0 1rem;
   }

   /* .sliderHouse>*>:nth-child(2){
    border: 2px solid red;
    width:17rem;
   } */

   /* .sliderHouse>*>*{
    border: 2px solid red;
    margin-left: 2rem;
    
   } */

   .sliderHouse>*>:nth-child(3){
    color: grey;
    background-color: rgb(125, 120, 120);
    border-radius: 50%;
   }
   .sliderHouse>*>:nth-child(1){
    color: grey;
    background-color: rgb(125, 120, 120);
    border-radius: 50%;
   }
}
