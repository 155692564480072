.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .modalDesImg{
    display: flex;
    width: 100%;
    height: 20rem;
  }
  .modalDesImg img{
    width: 100%;
    height: 100%;
  }


  .descriptionInfo{
    margin-top: auto;
    min-height: 40px;
    font-size: 15px;
    text-justify: inter-word;
    color: #000000;
    margin-bottom: 0;
    text-align: justify;
    max-width: 680px;
    line-height: 2em;
  }

  
  .footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
    margin-bottom: 10px;

  }
  
  footer > *:nth-child(1) {
    flex: 1 1 30%;
  }
  
  footer > *:nth-child(2) {
    flex: 1 1 70%;
  }
  
  .footer h2 {
    color: #131212;
    font-size: 14px;
    font-family: "poppins";
    font-weight: 550;
  }
  
  .logo {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1em;
  }
  
  .logo span {
    font-size: 12px;
    font-family: "poppins";
    font-weight: 400;
    color:  #131212;
  }
  .logo>*:nth-child(2){
    width: 13px;
    height: 13px;
  }

  .footer *{
    font-family: "poppins";
}

  @media(max-width:700px)
  {
    .container {
        gap: 0rem;
      }
    .modalDesImg{
        height: 15rem;
        width:100%;
      }

      .descriptionInfo{
            font-size: 14px;
            font-weight: 400;
            margin-bottom:0 ;
      }
      .footer h2 {
        font-size: 13px;
        font-weight: 500;
        margin: 0;
      }

      .logo span {
        font-size: 11px;
        font-weight: 300;
        font-style: italic;
        color:  #131212;
      }

  }