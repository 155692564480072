@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.GenderDistributionContainer {
  
    width: 576px;
    height: 316px;
    padding: 32px; /* Example padding */
    border-radius: 8px;
  background: var(--Neutral-0, #FFF);
  margin-right: 24px;

  }
.GenderDistributionHeader{
    width: 512px;
    height: 38px;
    margin-bottom: 10px;
  }
.GenderDistributionContainer h2 {
    margin-bottom: 4px;
    color: var(--Neutral-950, #0D0D0D);
    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  } 
.GenderDistributionContainer p{
    
  color: var(--Neutral-800, #303030);
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  } 
  