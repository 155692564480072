.scroll::-webkit-scrollbar {
    display:none;
   
}

.groupInfo{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 30%;
    gap:0.5rem;
    
    font-family: "poppins";
    margin-bottom: 0.8rem;;
     
}
.groupInfo > *:nth-child(1)
{
    flex: 1 1 30%;
    
}

.groupInfo > *:nth-child(1)
{
    flex: 1 1 70%;
   
}
