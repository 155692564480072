.User {
  background-color: white;
  /* border: 2px solid; */
  /* width: 80%; */
  /* margin-left: -1rem; */
}

/* .MessageArea {
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
} */
.No-Chat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background-color: white;
  width: 100%;
  font: normal normal normal 16px/25px Poppins;
}


.User_individual:hover {
  background-color: #f5f5f5 !important;
  border-bottom: 0.5px solid #dfdcdcf2 !important ;

  cursor: pointer;
  /* transform: translateX(-1px);
    transition: all ease 0.5s; */
}

.fileIcon {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}
.grpfile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 40vh;
  overflow-y: scroll;
}
.filecolumn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  height: 60px;
  background-color: #f5f3f3;
  padding: 20px 10px;
  align-items: center;
}

.aboutEdit {
  padding: 5px;
}
.aboutEdit:hover {
  background-color: #ebe9e9;
}

.remove-btn {
  color: black;
  opacity: 0%;
}

.aboutEdit:hover .remove-btn {
  opacity: 100%;
}

.memberAdmin-btn {
  color: black;
  z-index: 5;
}

.memberAdmin-btn:hover {
  background-color: #ebe9e9;
  cursor: pointer;
}

.memberRemove-btn {
  color: black;
}

.memberRemove-btn:hover {
  background-color: #ebe9e9;
  cursor: pointer;
}

.aboutDescriptionStyle {
  padding-left: 8px;
  overflow-y: scroll;
  height: 10vh;
}
.aboutDescriptionStyle::-webkit-scrollbar {
  width: 3px;
}
.aboutDescriptionStyle::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}





.addPeopleMemberScrollBar::-webkit-scrollbar {
  width: 4px;
}
.addPeopleMemberScrollBar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}

.addAdminScrollBar {
  margin-top: 10px;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.addAdminScrollBar::-webkit-scrollbar {
  width: 5px;
}
.addAdminScrollBar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}

.editGroupScrollBar {
  color: black;
}
.editGroupScrollBar::-webkit-scrollbar {
  width: 4px;
}
.editGroupScrollBar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}
.ant-image-mask {
  height: 7vh;
}
.react-icons {
  size: 5em;
  cursor: pointer;
  position: absolute;
}

.Input {
  max-height: 50px !important;
  min-height: 20px !important;
}

.editOption {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  font-family: poppins;
  padding: 3px 9px;
}
.editOption:hover {
  background-color: #eeeeee;
  border: 2px solid white;
}

.ant-form-item-explain > *:nth-child(2) {
  opacity: 0;
}
.input-form-item {
  margin-bottom: 0px;
}
.input-form-item
  > *:nth-child(1)
  > *:nth-child(1)
  > *:nth-child(1)
  > *:nth-child(1)
  > *:nth-child(1)
  > *:nth-child(1) {
  /* background-color: #a0a0a01c; */
  background-color: rgb(242, 242, 242);
  border-radius: 0px !important;
}

.Emojipick {
  height: 450px;
  width: 350px;

  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}
.emojiStyle {
  border: "2px solid red";
}

.EmojiPickerReact .epr-main {
  height: 390px !important;
}
.Emojipick {
  height: 522px !important;
}

textarea {
  border: none;
  outline: none;
  border-style: none;
  border-color: Transparent;
  height: 7vh;
}

/* .mentionInputContainer{
box-shadow:2px solid #F2F2F2 ;
height: 100%;
width: 80%;

} */

/* textarea{
    padding: 1px !important;
} */

/* .mentionInputContainer {
  border: 1px solid silver;
  height: 8vh;
  overflow-y: scroll;
  width: 100%;
  padding-top: 14px;
  padding-left: 12px;
} */
.mentionInputContainer::-webkit-scrollbar {
  width: 3px;
}
.mentionInputContainer::-webkit-scrollbar-thumb {
  background-color: silver;
}
@media screen and (max-width: 700px) {
  .addPeopleMemberScrollBar {
    margin-top: 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 30vh;}
  .User_individual {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 90vw;
  /* padding-left: 12px; */
  height: 100px;
  /* align-items: center; */
  background-color: white;
  border: none;
  /* justify-content: space-around; */
}
  .MessageArea {
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: rgb(227, 242, 248);
  }
  .mentionInputContainer {
    border: none;
    height: 8vh;
    overflow-y: scroll;
    width: 100%;
    padding-top: 14px;
    padding-left: 12px;
  }
}
@media screen and (min-width: 700px) {
  .addPeopleMemberScrollBar {
  margin-top: 15px;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}
  .User_individual {
  display: flex;
  flex-direction: row;
  /* gap: 10px; */
  width: 100%;
  /* padding-left: -1rem; */
  height: 100px;
  align-items: center;
  background-color: white;
  border: none;
  justify-content: space-around;
}
  .MessageArea {
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .mentionInputContainer {
    border: 1px solid silver;
    height: 8vh;
    overflow-y: scroll;
    width: 100%;
    padding-top: 14px;
    padding-left: 12px;
  }
}
