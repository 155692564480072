.SharedFileList {
  border-radius: 4px;
  gap: 4px;
  width: 100%;
  max-height: 48vh;
  overflow-y: auto;
}
.filteredMedia {
  align-items: center;
  padding: 3px;
  display: flow-root;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.Files {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #f5f3f3;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  height: fit-content;
  place-content: space-around;
  gap: 5px;
}
.fileText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.fileNameFit {
  overflow: hidden;
  border-radius: 4px;
  width: 60%;
  /* cursor: pointer; */
  height: 6vh;
  align-items: center;
  display: flex;
  scrollbar-width: none;
}
.shareIconcol {
  width: 3vw;
  height: 3vh;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.downloadIconcol {
  display: flex;
  align-items: center;
  width: 3vw;
  height: 3vh;
  cursor: pointer;
}
.filePreview {
  height: 30px;
  width: 30px;
  /* cursor: pointer; */
}
.SingleChatBox {
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgb(227, 225, 225);
  background-color: white;
}
/* .GrpChatBox {
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgb(227, 225, 225);
  background-color: white;
  max-height: 74vh;
} */
/* .singleChatheader {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 40px 20px;
  justify-content: space-between;
  border-bottom: 0.5px solid #dfdcdcf2;
} */
/* .ProfImg {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  border-radius: 5px;
  width: 40px;
  height: 40px;
} */
/* .Datediv {
  justify-content: center;
  display: flex;
} */
/* .dateBorder {
  border-bottom: 0.2px solid #e3e3e3;
  height: 0;
  width: 40%;
  margin-top: 16px;
} */
/* .DateBox {
  margin-top: 5px;
  border: 1px solid #e3e3e3;
  text-align: center;
  width: fit-content;
  border-radius: 4px;
  display: flex;
  font: normal normal bold 12px/22px Poppins;
  padding-right: 5px;
  padding-left: 5px;
} */
.SendFileText {
  font-style: italic;
  height: fit-content;
  margin-left: 10px;
  font-weight: 500;
}
/* .uploadFilesRow {
  position: relative;
  display: flex;
  overflow-y: scroll;
  height: auto;
  width: fitcontent;
} */
/* .SingleFile {
  margin-right: 15px;
  width: 11vw;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  margin-top: 15px;
  height: 8vh;
} */
.uploadedImg {
  width: 25%;
  border: 1px solid #b2b2b2;
  height: -webkit-fill-available;
  overflow: hidden;
}
.FileNameCol {
  width: 60%;
  font-weight: 500;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 100%;
}

.ChatMesage {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f2f2f2;
  height: 100%;
  border: none;
}

.messageBox {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.UploadCol {
  margin-left: 5px;
  width: 30%;
  height: 60%;
}
.Emojidiv {
  width: 30%;
}
.BtnEmo {
  border: none;
  background-color: #f2f2f2;
  padding: 0px;
}
/* .SendDiv {
  width: 30%;
  height: 60%;
} */
.SendBtn {
  background-color: #f2f2f2;
  border: none;
  padding: 0px;
  cursor: pointer;
}
.FileShareModaldiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.FileModalheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 20px 10px;
}
.ModalProfImgdiv {
  display: flex;
  align-items: center;
  gap: 20px;
}
.searchdiv {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 10vh;
}
.GrpAddPeopleBtn {
  border: 2px solid;
  height: 35px;
  width: 80px;
  color: white;
  background-color: #6fc78f;
}
.GrpAddAdminBtn {
  width: 120px;
  height: 38px;
  margin-top: 150px;
  margin-left: 330px;
  background: #6fc78f;
  color: white;
  cursor: pointer;
  /* border: 1px solid red; */
  border-radius: 4px;
}
.editGrpImg {
  width: 165px;
  width: 128px;
  height: 128px;
  margin: auto;
  /* border: 2px solid red; */
}
.Discardbtn {
  margin-top: 15px;
  color: black;
  background: #efefef;
}
.saveBtn {
  margin-top: 15px;
  margin-left: 15px;
  background: #6fc78f;
  color: white;
}
.ExitGrpdiv {
  font-size: 20px;
  font-weight: bold;
  font: normal normal normal 16px/30px Poppins;
  color: #000000;
  opacity: 1;
  padding: 5px 6px;
}
.cancelBtn {
  font-weight: bold;
  color: black;
  width: 11vw;
  height: 7vh;
}
.ExitBtn {
  font-weight: bold;
  color: black;
  width: 11vw;
  height: 7vh;
}
.closediv {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.itemmainDiv {
  display: flex;
  flex-direction: column;
  padding: 16px 10px;
}
.ChatDesc {
  border: 1px solid #e5e5e5;
  margin-top: -10px;
}
.DesDiv {
  font-weight: bold;
  padding-left: 8px;
}
.EditModalDiv {
  display: flex;
  /* border: 1px solid #e5e5e5; */
  flex-direction: column;
  padding: 16px 10px;
  margin-top: 10px;
}
.EditDiv {
  display: flex;
  justify-content: space-between;
}
.PopConfirmdiv {
  color: red;
  padding: 4px 10px;
  margin-top: 10px;
}
.Btnconfirm {
  color: red;
  font-weight: bold;
  padding: 4px 0px;
  width: 32vw;
  position: fixed;
}
.leaveGrpBtn {
  font-weight: bold;
  color: red;
  display: flex;
  justify-content: flex-start;
  opacity: 60%;
}
.AddPeopleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.AddPeople2 {
  margin-left: 10px;
  cursor: pointer;
  margin-top: 20px;
}

/* // groupallChatMainSideBar */
.scroll {
  width: 100%;
  height: 53vh;
  overflow-y: scroll;
  margin-top: 16px;
  font-size: 10px;
  background-color: white;
}
.scroll::-webkit-scrollbar {
  width: 5px;

}
.scroll::-webkit-scrollbar-thumb{
  background-color: silver;
}
.unseenMsgGrpChatMenu {
  background-color: #a661ff;
  color: white;
  padding: 4px;
  height: 24px;
  width: 20px;
  border-radius: 5px;
  text-align: center;
}
.ShareDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.ShareBtn {
  margin-top: 15px;
  background: #6fc78f;
  color: white;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 4px;
  /* width: 10vw; */
  height: 5vh;
}

.profImgdiv {
  display: flex;
  align-items: center;
}
.profImg {
  width: 30;
  height: 30;
  border-radius: 50%;
  margin-right: 10;
}
.ShareText {
  font-family: sans-serif;
  font-weight: bold;
  padding-bottom: 4vh;
}
.selectlist {
  width: 100%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.AddMsgdiv {
  margin-top: 3vh;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.searchChatbar {
  width: 100%;
}
.Grpchatload {
  margin-top: 10vh;
}
.chatWindCol {
  height: 100%;
}
.createchatdiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.text {
  color: #a5a5a5;
}
.createformbtn {
  display: flex;
  justify-content: flex-end;
}
.uploadimgcreatecht {
  height: 26vh;
  width: 20vw;
}
.submitbtn {
  display: flex;
  justify-content: flex-end;
}
.addpeoplesubmit {
  display: flex;
  justify-content: flex-end;
}
.unseenmsgsdiv {
  background-color: #a661ff;
  color: white;
  padding: 4px;
  height: 24px;
  width: 20px;
  border-radius: 5px;
  text-align: center;
}
.lastactivediv {
  width: 90px;
}
.dropdowndiv {
  border-top: 0.5px solid #bfbfbf;
  margin: 0px 5%;
}
.nouserdiv {
  padding-left: 10px;
}
.newChatdiv {
  padding-left: 10px;
  font-weight: bold;
}
.unseenchatdropdown {
  background-color: #a661ff;
  color: white;
  padding: 4px;
  height: 24px;
  width: 20px;
  border-radius: 5px;
  text-align: center;
}
@media screen and (max-width: 700px) {
  .scroll{
    min-height:63vh;
    width:100%;
    overflow-y: auto;
  }
  .messageform {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  border: none;
  padding: 6px;
}
.SingleFile {
  position: relative;
  margin-right: 15px;
  width: 25vw;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  margin-top: 15px;
  height: 8vh;
}

.RemoveIcon {
  position: absolute; 
  top: -10px; 
  right:-10px; 
  width: 20px;
  z-index: 1;
}

  .editGrpImg{
    width: 175px;
    /* width: 128px; */
    height: 128px;
    /* margin: auto; */
    /* margin-top: 20px; */
    margin-left: -12px;
    border: 2px solid white;

  }

  .GrpAddAdminBtn{
    width: 120px;
    height: 38px;
    /* margin-top: 150px; */
    margin-left: 6px;
    background: #6fc78f;
    color: white;
    cursor: pointer;
    /* border: 1px solid red; */
    border-radius: 4px;

  }

  /* .uploadFilesRow {

  } */
  .more {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
  .ProfImg {
    display: none;
  }
  .ProfImgMobile {
    display: flex;
    align-items: center;
    /* cursor: pointer; */
    gap: 10px;
    width: 40px;
    height: 40px;
  }
  .GrpChatBox {
    display: flex;
    flex-direction: column;
    /* border-left: 1px solid rgb(227, 225, 225); */
    background-color: white;
    max-height: 85vh;
  }
  .dateBorder {
    border-bottom: 0.2px solid #e3e3e3;
    height: 0;
    /* width: 40%; */
    margin-top: 16px;
  }

 
  .singleChatheader {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 40px 20px;
    justify-content: space-between;
    border-bottom: 0.5px solid #dfdcdcf2;
    background-color: rgb(241, 248, 251);
  }
  .SendDiv {
    width: 30%;
    height: 60%;
    margin-right: 5px;
  }
    .DateBox {
    margin-top: 5px;
    /* border: 1px solid #707070; */
    text-align: center;
    min-width: 8vw;
    border-radius: 4px;
    display: flex;
    font: normal normal bold 12px/22px Poppins;
    padding-right: 5px;
    padding-left: 5px;
    background-color: white;
        /* border:2px solid blue; */

  }
   .dateBorder {
    border-bottom: 0.2px solid #707070;
    height: 0;
    width: 30%;
    margin-top: 16px;
    /* border:2px solid green; */

  }
  .Datediv {
    justify-content: center;
    display: flex;
    /* border:2px solid red; */
  }
}
@media screen and (min-width: 700px) {
   .scroll{
    height:57vh;
    /* height: 54%; */
    overflow-y: auto;
    /* border:2px solid red */
  }
  .messageform {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  border: none;
}
  /* .SingleFile {
    margin-right: 15px;
    width: 11vw;
    border: 1px solid #b2b2b2;
    border-radius: 4px;
    margin-top: 15px;
    height: 8vh;
  } */
 .SingleFile {
  position: relative;
  margin-right: 15px;
  width: 13vw;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  margin-top: 15px;
  height: 8vh;
}

.RemoveIcon {
  position: absolute; 
  top: -10px; 
  right:-10px; 
  width: 20px;
  z-index: 1;
}
  .uploadFilesRow {
    position: relative;
    display: flex;
    overflow-y: scroll;
    height: auto;
    width: fitcontent;
  }
  .more {
    height: 16px;
    width: 16px;
    cursor: pointer !important;
  }
  .ProfImgMobile {
    display: none;
  }
  .ProfImg {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 40px;
    height: 40px;
  }
  .GrpChatBox {
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgb(227, 225, 225);
    background-color: white;
    max-height: 75vh;
    width:58vw;
    margin-top: 14px;
  }
  /* .dateBorder {
    border-bottom: 0.2px solid #e3e3e3;
    height: 0;
    width: 40%;
    margin-top: 16px;
  } */
  .DateBox {
    margin-top: 5px;
    border: 1px solid #e3e3e3;
    text-align: center;
    width: fit-content;
    border-radius: 4px;
    display: flex;
    font: normal normal bold 12px/22px Poppins;
    padding-right: 5px;
    padding-left: 5px;
  }
  .dateBorder {
    border-bottom: 0.2px solid #e3e3e3;
    height: 0;
    width: 40%;
    margin-top: 16px;
  }
  .Datediv {
    justify-content: center;
    display: flex;
  }
  .SendDiv {
    width: 30%;
    height: 60%;
  }
  .singleChatheader {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 40px 20px;
    justify-content: space-between;
    border-bottom: 0.5px solid #dfdcdcf2;
  }
}
