.profile-details {
  display: flex;
  gap: 0.2rem;
  flex-wrap: nowrap;
  /* margin: -20px 50px 40px 50px; */
  width: 95%;
}

.profile-div {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 2rem;
  gap: 10px;
  border: 2px solid #eeeeee;
  background-color: #f5f7fa;
}

.profile-div > *:nth-child(1) {
  flex: 1 1 25%;
  padding-left: 40px;
  gap: 1em;
}
.profile-div > *:nth-child(2) {
  flex: 1 1 30%;
  gap: 1em;
  padding-left: 20px;
}
.profile-div > *:nth-child(3) {
  flex: 1 1 10%;
  gap: 1em;
}

.profile-div > *:nth-child(4) {
  flex: 1 1 35%;
  gap: 1em;
}

.profile-div .empInfo {
  align-items: left;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.empInfo img {
  width: 126px;
  height: 126px;
}
.empInfo h2 {
  font-size: 20px;
  font-weight: 500;
  font-family: "poppins";
  color: #000000;
  margin: 0;
}
.empInfo p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  font-family: "poppins";
  color: #696969;
}
.empInfo h3 {
  font-family: "poppins";
  font-size: 14px;
  font-weight: 500;
  color: #222222;
}

.empInfo h5 {
  margin-bottom: 7px;
  font-family: "poppins";
  font-size: 14px;
}

.profile-details > *:nth-child(1) {
  flex: 1 1 75%;
  min-width: 25rem;
}

.profile-details > *:nth-child(2) {
  flex: 1 1 25%;
  min-width: 5rem;
}

.houseCard {
  margin-right: 1.2rem;
  margin-left: -1.3rem;
  align-items: right;
  position: relative;
  padding-right: 0;
}

.houseCard h2 {
  margin-top: 1rem;
  margin-left: 3rem;
  color: #cae0d2;
  font-size: 25px;
  font-family: "popins";
  font-weight: bold;

  text-decoration: underline;
  cursor: pointer;
}

.div2 {
  background-color: #cae0d2;
  max-width: 240px;
  height: 139px;
  margin-left: 2.8rem;
  margin-right: 0;
}

.div2 h1 {
  color: #cae0d2;
  font-size: 22px;
  font-family: "popins";
  font-weight: bold;
}

.prithvi {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  padding-top: 1.2rem;
}

.modal {
  height: 403px;
  margin: 3rem;
  font-size: 16px;
}

.modal div {
  padding: 40px 0 0 50px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.modal div > * {
  font-family: "poppins";
}

.modal div h1 {
  font-size: 26px;
  font-weight: bold;
  font-family: sans-serif;
  color: #447a57;
}
.modal div h2 {
  font-size: 17px;
  font-weight: 500;
  font-family: "poppins";
  color: #000000;
}

.modal div p {
  font-size: 14px;
  margin: 0;
  color: #000000;
  font-weight: 700;
}

.modal div span {
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
}

.modalCardImage {
  height: 190px;
  width: 190px;
  position: absolute;
  right: 2.2rem;
  bottom: 2.2rem;
  background: transparent;
  z-index: 30;
}

.userProfileLogo {
  height: 160px;
  width: 160px;
  position: absolute;
  right: 4.5rem;
  top: 4.5rem;
}

.houseCardImage {
  height: 135px;
  width: 105px;
  position: absolute;
  margin-left: 11rem;
  /* margin-bottom: 20rem; */
  top:0;

  /* margin-left: 13.5%;*/
  margin-top: -15.7%; 
}

.mobEmpProfile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-top: 0.7rem;
}

.mobEmpProfile > *:nth-child(1) {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
}

.mobEmpProfile > *:nth-child(1) > *:nth-child(1) {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.mobEmpProfile > *:nth-child(2) {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* border: 2px solid red; */
}

.mobEmpProfile > *:nth-child(2) > *:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.mobEmpProfile > *:nth-child(2) > * {
  margin: 0;
  padding: 0;
}

.mobEmpProfile > *:nth-child(2) > *:nth-child(1) > *:nth-child(2) {
  font-size: 12px;
  font-weight: 400;
  color: grey;
  align-self: center;
  font-style: italic;
}

.mobEmpProfile > *:nth-child(2) > *:nth-child(2) {
  color: gray;
  font-weight: 400;
  font-size: 12px;
}

.mobEmpProfile > *:nth-child(2) > *:nth-child(3) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobEmpProfile > *:nth-child(2) > *:nth-child(3) > :nth-child(1) {
  text-decoration: underline;
  font-weight: 500;
}



.additonalDetails > * {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  gap: 1rem;
}

.additonalDetails > * > :nth-child(1) {
  flex: 1 1 45%;
  font-size: 13px;
  font-weight: 600;
}

.additonalDetails > * > :nth-child(2) {
  flex: 1 1 50%;
  font-size: 13px;
  color: grey;
  font-weight: 500;
}

.additonalDetails > *:nth-child(2) {
  align-items: flex-start;
}
.additonalDetails > *:nth-child(2) > *:nth-child(2) > * {
  color: gray;
  font-size: 13px;
  font-weight: 500;
}

@media (max-width: 800px) {
  .additonalDetails {
  display: flex;
  flex-direction: column;
  border: 1.3px solid rgb(206, 199, 199);
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 5;
  background-color: #f8f9f9;
  gap: 1rem;
  width:100%;
}
  .modal {
    height: 315px;
    margin: 0rem;
    font-size: 16px;
    border-radius: 5px;
  }

  .modalCardImage {
    height: 80px;
    width: 80px;
    right: 0.5rem;
    bottom: 0.5rem;
  }

  .userProfileLogo {
    height: 80px;
    width: 80px;
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 50%;
  }

  .modal div {
    padding: 15px 0 0 15px;
    display: flex;
    flex-direction: column;
  }

  .modal div h1 {
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif;
    color: #447a57;
    margin: 0;
  }
  .modal div h2 {
    font-size: 14px;
    font-weight: 600;
    font-family: "poppins";
    color: gray;
  }

  .modal div p {
    font-size: 14px;
    margin: 0;
    color: #000000;
    font-weight: 600;
  }

  .modal div span {
    font-size: 14px;
    font-weight: 600;
    font-family: sans-serif;
    color: gray;
  }
}

@media (min-width: 800px) {
.additonalDetails {
  display: flex;
  flex-direction: column;
  border: 1.3px solid rgb(206, 199, 199);
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 5;
  background-color: #f8f9f9;
  gap: 1rem;
}}