


.main div {
  display: flex;
  align-items: center;
  height: 5vh;
}
.main {
  margin-top: 0.5rem;
}
.main nav ul {
  padding-left: 0;
  flex-direction: column;
  list-style: none;

}
.main nav ul li {
  margin-left: 0;
  display: flex;
  flex: 1 1 100%;
  border-radius: 4px;
  margin-right: 16px;
 width: 100%;

}

.main div img {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}
.main div span {
  font-family: sans-serif;
}


.brigosha p {
  font-size: 11px;
  color: grey;
  margin: 0 auto;
}
.brigosha span {
  font-size: 12px;
  color: #0086ff;
  font-family: "poppins";
  font-weight: 600;
}
.setting {
  position: absolute;
  justify-content: center;
  text-align: center;
  bottom: 6rem;
}


.houseNav {
  height: auto;
  margin: 0 0 0 1.3rem;
  border: 1px solid;
}
.valid {
  position: absolute;
  margin-top: -50rem;
}

@media (max-width: 800px) {
  .main {
    margin-top: 1rem;
  }
}
@media (max-width: 700px) {



  .mobmenu{
    transition: width 0.3s ease;
    justify-content: space-between;
    display: flex !important;
    flex-direction: column;
    height: 89% !important;
    width: 100vw;
    align-items: baseline !important;
}
  .sidebar {
  background-color: #ffffff;
  display: flex;
  color: black;
  flex-direction: column;
  height: 88vh;
 justify-content: space-between;
}
/* .smooth-closing-sidebar {
  transition: width 0.3s ease;
  display: flex;
  justify-content: space-between;
  border:2px solid red
} */

.houses span {
  font-family: "poppins";
  font-weight: 600;
  /* padding-right: 1rem; */
  font-size: 10px;
}

/* .houses .togg {
  margin-left: 1rem;
} */

.houses {
  margin: 0;
  font-size: 8px !important;
  font-weight: 500;
  font-family: "poppins";
  cursor: pointer;
   /* padding: 5px */
}


  
  .brigosha {
    height: fit-content;
  display: flex;
  width: 100%;
  flex-direction: column;
  /* justify-content: center;
  text-align: center;
  align-items: center; */
  margin-bottom: 2.6rem;
  align-items: flex-start !important;
  margin-left: 16px;

}
  .element {
  width: 100vw;
}
 .sidebar {
      padding-left: 8px;
    /* height: 100vh; */
    
    height: 100%;

  }
  .main {
    margin-top: 1rem;
     margin-left: -7px;
    /* margin-left: 22px; */
    /* width:50vw; */
    /* box-shadow:0px 3px 6px #00000036;  */
    height: 97vh;
  }
  /* .main {
    margin-top: 0rem;
    margin-left: -7px;
    border: 2px solid red;
    width: 50vw;
    box-shadow: 44px 35px 60px 161px #434242;
} */
  .closed {
    height: fit-content;
  }
}
@media (min-width: 700px) {
  .sidebar {
  background-color: #ffffff;
  display: flex;
  color: black;
  flex-direction: column;
  width: 100%;
  height: 88vh;
 justify-content: space-between;
}
  .houses span {
  font-family: "poppins";
  font-weight: 600;
  padding-right: 1rem;
  font-size: 10px;
}

.houses .togg {
  margin-left: 1rem;
}

.houses {
  margin: 0;
  font-size: 8px !important;
  font-weight: 500;
  font-family: "poppins";
  cursor: pointer;
  padding-left: 16px;
}
  .brigosha {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* margin-left: auto; */
 
  margin-bottom: 2.6rem;
}
  .element {
  width: 100%;
}
  .sidebar>*:nth-child(1)
{
  padding-left: 3px;
} 
  .main {
    margin-top: 3rem;
  }
}
