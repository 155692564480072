@media screen and (max-width: 700px) {
    .custom-player{
      width: 100% !important;
      height: 100% !important;
    }
  }
  @media screen and (min-width: 700px) {
    .custom-player{
      width: 100% !important;
      height: 100% !important;
    }
  }