@media screen and (min-width: 700px) {
  #wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .slick-slider {
    width: 200px;
  }

  .slick-dots {
    text-align: center;
  }
  .slick-dots > li {
    display: inline-block;
  }
  .WhtsImg {
    width: 200px;
    height: 147px;
    display: flex;
  }

  .ImageContainer {
    width: 200px;
    height: 147px;
    display: flex;
  }
}
@media screen and (max-width: 700px) {
  #wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .slick-dots {
    text-align: center;
  }
  .slick-dots > li {
    display: inline-block;
  }
  .WhtsImg {
    width: 250px;
    height: 96%;
    display: flex;
    
  }

  .ImageContainer {
    width: 250px;
    height: 200px;
    display: flex;
    margin-top: 4px;
  }
  .slick-slider .slick-initialized {
    width: 350px;
    border:2px solid red
  }
}
