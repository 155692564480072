.uploadedImagecontainer{
    position: relative;
}

.icon{
    position: relative;
    border: none;
    z-index:11;
    transition: all ease 0.2s;
    opacity: 0;
    /* bottom: 22px; */
    display: grid;
    grid-template-columns: 1fr 4fr;
    background: #000;
    /* width: 100%; */
    cursor: pointer;
    height: 22px;
    border-radius: 4px;
    margin-top: 20px;
}

.icon-img{
    height: 15px;
    width: 100%;
    display: flex;
    margin-top: 3px;
    /* bottom: 0; */
    /* align-items: center; */
    /* justify-content: center; */
}

.image:hover .icon{
    opacity: 0.6;
}

.image{
    /* position: absolute; */
    /* opacity: 0; */
    z-index: 1;
    /* padding: 10px; */
    /* height: 100%; */
    /* border:2px solid red */
}



.icon>*:nth-child(2){
    /* border:1px solid yellow; */
    color: #ff6969;
    font-family: 'poppins';
    font-size: small;
}

