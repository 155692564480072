.scroll::-webkit-scrollbar {
  width: 5px;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}
.container {
  background-color: #f3f6fa;
  margin-bottom: 0;
  padding: 1rem 1rem 0 1rem;
  height: 100%;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.Title_container {
  display: flex;
  justify-content: space-between;
}
.Title {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: poppins;
}
.Houses {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding-right: 2vw;
  z-index: 0;
  position: relative;
}

.houses-logo {
  position: absolute;
  top: -12%;
  right: -7%;
  width: 7vw;
  height: 7vw;
}
.houses-info {
  padding: 15px;
  height: 0px;
}
.info-heading {
  font-family: poppins;
  font-size: 24px;
  font-weight: 600;
}
.captain-info {
  font-size: 16px;
  font-family: poppins;
  font-weight: 400;
  height: auto;
  display: flex;
}
.team-size {
  font-size: 16px;
  font-family: poppins;
  font-weight: 400;
}
.captain-name {
  font-size: 16px;
  font-weight: 700;
  font-family: poppins;
  width: 85px;
}
.power-house {
  position: fixed;
  transform: rotate(90deg);
  width: 127px;
  padding-right: 18px;
  margin: 17px 0px 0px 202px;
  font-size: 12px;
}
.champions-tag {
  padding-top: 4px;
  width: 91px;
  margin-top: 45px;
  font-weight: bold;
  margin-left: 25px;
}
.champions-cup {
  width: 50px;
  height: 50px;
  margin-left: 160px;
  margin-top: 88px;
}
.overview-content {
  margin-top: 40px;
}
.whats-happening-container {
  box-shadow: 0px 3px 6px #00000036;
  background-color: #e9f4f8;
  border-radius: 4px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* border: 2px solid red; */
}
.whats-happening-title {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: poppins;
  display: flex;
  justify-content: flex-start;
  /* border: 2px solid red; */
  /* text-align: center; */
}
.whats-happening-content {
  width: 100%;
  padding-top: 10px;
  overflow-y: auto;
  height: 500px;
  justify-content: center;
}
.whats-happening-content::-webkit-scrollbar {
  width: 5px;
}
.whats-happening-content::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}

.events-list:hover {
  box-shadow: 0px 3px 8px #00000053;
  transform: scale(1.02);
  transition: 0.6s ease-in-out;
}
/* .events-head {
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
cursor: pointer;
border:2px solid green
} */
/* .events-name {
    display: flex !important;
    justify-content: center;
    max-width: 90% !important;
    padding: 5px 0px 0px 10px;
    font: normal normal bold 16px/20px Poppins;
} */
/* .events-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width:100%
} */
.profile-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.profile-name {
  font: normal normal 300 13px/20px Poppins !important;
  font-weight: 600;
}

.events-image {
  height: 95%;
  width: 95%;
  border-radius: 3%;
  padding: 5px;
  /* margin-bottom: 10px; */
}
.events-img-footer {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  /* justify-content: space-evenly; */
  margin-left: 22px;
  padding: 2px;
  position: relative;
  z-index: 1;
}
.events-img-date {
  margin: 3px 5px 5px 5px;
  font-family: poppins;
  font-size: 11px;
}
.events-img-globe {
  width: 24px;
  height: 24px;
  margin-left: 0px;
}



.Event-content {
  height: 80vh;
  overflow-y: scroll;
  overflow-x: unset;
}
.Event-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;

}
.Event-content::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}


.Event_date_container {
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  margin-top: 10px;
  font-size: 1.1vw;
  background: white;
  margin: 8px 10px 10px 10px;
}

  .Event-name-container {
  width: 70%;
  height: 95%;
  margin-top: 10px;
  margin-left: 10px;
}

.Event-name {
  height: 100%;
  font-size: 18px;
  font-weight: 600;
  font-family: poppins;
  width: 260px;
  margin-bottom: 0px;
}
.Event-info {
  margin-top: auto;
  height: 100%;
  /* min-height: 40px;
  max-height: 70px; */
  font-size: 13px;
  /* margin-left: 10px; */
}

@media screen and (max-width: 1400px) {
  .Houses {
    width: 84vw;
    padding-right: 4vw;
  }
}
@media screen and (min-width: 1200px) {
  .Houses {
    width: 85vw;
    padding-right: 1vw;
  }
  .houses-logo {
    width: 6vw;
    height: 7vw;
  }
}
@media screen and (max-width: 700px) {
  .Event-name-container {
  width: 70%;
  height: 95%;
  margin-top: 10px;
  margin-left: 10px;
}
  .slick-prev:before{
  color:rgb(184, 182, 182)
}
.slick-next:before{
  color: rgb(184, 182, 182)
}
  .Event-container {
  width: 100%;
  height: 80vh;
  justify-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.7vw;
  background-color: #e9f4f8;
  align-self: end;
  margin-right: 10px;
  box-shadow: 0px 3px 6px #00000036;
  border-radius: 4px;
  padding: 10px;
}
  .Event-month {
  text-align: center;
  font-family: poppins;
  font-size: 12px;
  font-weight: 400;
}
  .Event-date {
  text-align: center;
  font-family: poppins;
  font-size: 18px;
  font-weight: 700;
      margin-top: 3px;
}
  .Event-details {
  width: 97%;
  height: 18%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 10px 10px 0px;
  justify-items: left;
  cursor: pointer;
}
  .View-more-container{
    width: 100%;
    justify-content: center;
    display: flex;
margin-top: 20px;
  }
  .events-img-date {
    /* margin: 15px 5px 5px 5px; */
    font-family: sans-serif;
    font-size: 13px;
    color: white;
  }
  /* .View-more {
  }
  .read-more-container {
  } */
  .Happeningcol {
    display: none;
  }
  .Cont2 {
    width: 100%;
  }
  .Houses {
    display: none;
  }

  /* .slider {
    height: 44vh;
    width: 100%;
    margin-top: -9vh;
    display: flex;
    justify-content: space-between;
    padding-right: 2vw;
    position: relative;
    margin-top: 10px;
  } */
  .slider {
      height: 44vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: -29px 0px 0px 5px;
  }
  .houses-grid {
    width: 91%;
    position: relative;
    border-radius: 7px;
    height: 174px;
    box-shadow: #00000014 10px 10px 20px 6px;
    margin-top: 9vh;
  }

  .houses-logo {
    width: 33%;
    height: auto;
    z-index: 0;
    margin-top: -3px;
    margin-right: 2px;
  }

  .container {
    background-color: white;
    margin-bottom: 0;
    height: 89vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
   position: fixed;
   margin-top: 7px;
  }

  .Title_container {
    display: flex;
    justify-content: space-between;
  align-items: center;
  }

  .Title {
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    font-family: poppins;
    margin-top: 5px;
  }
  .Yeardropdown {
    display: flex;
    z-index: 1;
  }
  .HappeningcolMobile {
    width: 100%;
    height: 92vh;
    box-shadow: 6px -4px 11px #00000036;
  }
  .EventsCol {
    margin-top: 9px;
    width: 100%;
    height: 50%;
    box-shadow: 6px 6px 11px #00000036;
  }

  .captain-info {
    font-size: 16px;
    font-family: poppins;
    font-weight: 400;
    height: auto;
    display: flex;
    height: auto;
  }
  /* .PowerHseCol {
    width: fit-content;
    height: 100%;
    margin-left: 53vw;
    margin-top: -10vh;
  } */

  .whats-happening-content .events-list {
    margin-bottom: 15px;
    width: 100%;
    height: 250px;
    background: #fff;
    display: flex;
    row-gap: 0px;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000036;
    border-radius: 5px;
  }
  .View-more {
    color: blue;
    cursor: pointer;
  }
  .winnerCup {
    width: 46px;
    height: 46px;
    margin-top: -4vh;
    display: flex;
    justify-content: flex-end;
    margin-left: 52vw;
  }
  /* .events-head {
    margin-top: 9px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-items: center;
  } */
  .events-img-footer {
    background-color: rgba(0, 0, 0, 0.5);
    margin-left: 0px;
        margin-top: 136px;
    border-radius: 0 0 5px 5px;
    position: absolute;
    z-index: 1;
    height: 61px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .whats-happening-content {
    width: 100%;
    overflow-y: auto;
    background-color: #e9f4f8;
    overflow-x: hidden;
    padding-bottom: 4vh;
    height: 82vh;
    padding: 12px;
  }
  .Event-title {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: poppins;
    margin-top: 8px;
    box-shadow: 3px -7px 11px #00000036;
  }
    .events-list {
    width: 100%;
    height: 300px;
    background: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    /* row-gap: 16px; */
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000036;
    border-radius: 5px;
    align-items: stretch;
  }
   .events-profile {
    padding: 5px 0px 0px 10px;
  display: flex;
  gap: 10px;
  width:100%
}
  .events-name {
    display: flex !important;
    max-width: 90% !important;
    padding: 5px 0px 0px 10px;
    font: normal normal bold 16px/20px Poppins;
}
  .events-head {
    margin-left: 5px;
   margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    align-items: flex-start;
}
}


@media screen and (min-width: 700px) {


.slick-prev:before{
  color:rgb(184, 182, 182)
}
.slick-next:before{
  color: rgb(184, 182, 182)
}
  .houses-grid {
  width: 100%;
  position: relative;
  border-radius: 7px;
  height: 174px;
  box-shadow: #00000014 10px 10px 20px 6px;
  z-index: 1;
}
  .Event-container {
  width: 100%;
  height: 88%;
  justify-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.7vw;
  background-color: #e9f4f8;
  align-self: end;
  margin-right: 10px;
  box-shadow: 0px 3px 6px #00000036;
  border-radius: 4px;
  padding: 10px;
}
  .Event-month {
  text-align: center;
  font-family: poppins;
  font-size: 14px;
  font-weight: 400;
}
  .Event-date {
  text-align: center;
  margin-top: 15px;
  font-family: poppins;
  font-size: 20px;
  font-weight: 600;
}
  .Event-details {
  width: 97%;
  height: 23%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 10px 10px 0px;
  justify-items: left;
  cursor: pointer;
}
  .whats-happening-container {
    box-shadow: 0px 3px 6px #00000036;
    background-color: #e9f4f8;
    border-radius: 4px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border: 2px solid red; */
  }
  .events-img-date {
    margin: 3px 5px 5px 5px;
    font-family: poppins;
    font-size: 11px;
  }
  .HappeningcolMobile {
    display: none;
  }
  .events-list {
    width: 220px;
    height: 300px;
    background: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    row-gap: 16px;
    flex-direction: column;
    margin-left: 25px;
    box-shadow: 0px 3px 6px #00000036;
    border-radius: 5px;
    align-items: stretch;
  }
  .events-profile {
    padding: 5px 0px 0px 10px;
  display: flex;
  gap: 10px;
  width:100%
}
  .events-name {
    display: flex !important;
    max-width: 15vw !important;
    padding: 5px 0px 0px 10px;
    font: normal normal bold 16px/20px Poppins;
}
  .events-head {
    margin-left: 5px;
   margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    align-items: flex-start;
}
  .slider {
    display: none;
    width: 100%;
  }
  .Happeningcol {
    width: 69%;
  }
  .EventsCol {
    width: 100%;
    height:70%;
  }
  .whats-happening-content {
    width: 100%;
    padding-top: 10px;
    overflow-y: auto;
    height: 500px;
   
  }
  .Event-title {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: poppins;
  }
  .ChampionCol {

    margin-top:32px;
    width: 100%;
    text-align: center;
  }

  .winner {
    width: 90vw !important;
    height: 20vh;
    margin-top: -385px;
    /* height:90vh; */
  }
  .whats-happening-title{
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: poppins;
    text-align: center;

  }
}

