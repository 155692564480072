@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.UnassignedContainer{
    
    width: 456px;
    
    height: 383px;
    padding: 32px; /* Example padding */
    border-radius: 8px;
  background: var(--Neutral-0, #FFF);
  margin-right: 24px;
  gap: var(--M-M, 24px);


}
.Unassigned-header{
  width: 420px;
  height: 38px;
}
.Unassigned-header h2{
  color: var(--Neutral-950, #0D0D0D);
font-family: "Roboto";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.Unassigned-header p{
  color: var(--Neutral-800, #303030);
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.UnassignedContent1{
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 24px;
  
}
.count{
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 48px;
}
.UnassignedBottom{
  margin-top: 24px;
  
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 52px;
  gap: 0px;
  align-items: center;
}
/* GoToUsersButton.css */
.go-to-users-button{
  background-color: #1da1f2; /* Blue background color */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 14px; /* Rounded corners */
  padding: 8px 20px; /* Padding */
  font-size: 12px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  outline: none; /* Remove outline */
  display: inline-block; /* Inline block display */
  text-align: center; /* Center text */
}


.go-to-users-button:hover {
  background-color: #0d8cf2; /* Darker blue on hover */
}


.UnassignedBottomContent h3{
  margin-bottom: 0px;
  width: 232px;
  font-family: "Roboto";
}
.UnassignedBottomContent p{
  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
  font-family: "Roboto";
  
}