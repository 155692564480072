.houseModal{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.houseModal>*:nth-child(1)
{
    flex: 1 1 50%;
}

.houseModal>*:nth-child(2)
{
    flex: 1 1 50%;
}

@media(max-width:700px)
{
    .houseModal{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 400px;
        overflow-y: auto;
        padding: 10px 10px 0px 0px;
        /* width:100% */
    }

}

@media (min-width:700px){
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 4px;
}
}


