.eventCaptainDiv{
overflow-y: scroll;
width: 100%;
height: 600px;
margin-left: 9px;
}
.eventStatus{
position: absolute;
right: 2rem;
font-style: italic;
font-weight: 800;
border-radius: 10px;
padding: 5px;
}
.eventCard{
width: 100%;
height: 200px;
display: flex;
flex-wrap: nowrap;
align-items: center;
cursor: pointer;
}
.eventTitle{
    font-family: "poppins";
    font-size: 24px;
    font-weight: 600;
    max-width: 20vw!important;
}
.eventDescription{
box-sizing: border-box;
padding: 5px;
width: 95%;
height: 123px;
font-size: 14px;
font-family: "poppins";
max-height: 130px;
}


@media(max-width:700px)
{
    .eventCard{
        width: 100%;
        height: auto;
        display: grid;
        padding: 0;
        margin-bottom: 1rem;
        }

        .eventTitle{
            font-size: 20px;
            font-weight: 600;
            /* max-width: !important; */
            width: none;
            /* border: 2px solid red; */
        }

        .title-div{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .title-div >*:nth-child(2)
        {
            display: flex;
            flex-direction: row;
            gap:0.5rem;
            align-items: center;
            justify-content:space-between;
        }
        .title-div >*:nth-child(2)>*:nth-child(2)
        {
            font-weight: 700;
            font-style: italic;
        }

        .eventDescription{
            width: 100%;
            height: auto;
            max-height: auto;
            }

}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    /* justify-content: space-around; */
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: -8px;
}
  
  .flex-item {
     padding: 1rem;
    width: 100%;
    /* height: 150px; */
    margin-top: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border: 1px solid white;
    border-width: 1px;
    cursor: pointer;
  }

  .flex-item111{
    width: 50%;
    /* padding: 4px 10px; */
    /* height: 150px; */
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid rgb(201, 201, 208);
    border-right: 3px solid rgb(201, 201, 208);
    border-width: 1px;
    /* border-bottom: 1px solid; */
 
  }
   .flex-item112{
    width: 38vw;
    margin-left: 1vw;
    /* border:2px solid red; */
    /* padding: 4px 10px; */
    /* height: 150px; */
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid rgb(201, 201, 208);
    border-right: 3px solid rgb(201, 201, 208);
    border-width: 1px;
    /* border-bottom: 1px solid; */
 
  }

  .listViewCard{
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    border-width: 1px;
    width:100vw;
    background-color: white;
   height: 30vh;
   align-items: center;
    }
  .EventList{
     /* margin-left:365px; */
    display: flex;
    width: 96%;
  }

.EventList2{
  /* margin-left:365px; */
    display: flex;
    width: 96%;
}
  .txtOverFLow{
    display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
overflow: hidden
  }

  .headTxt{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden 
  }
