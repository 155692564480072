.uploadedImagecontainer{
    position: relative;
}

.icon{
    position: relative;
    border: none;
    z-index:10;
    transition: all ease 0.2s;
    opacity: 0;
    bottom: 22px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    background: #000;
    width: 100%;
    cursor: pointer;
    height: 22px;
    border-radius: 4px;
}

.icon-img{
    height: 15px;
    width: 100%;
    display: flex;
    margin-top: 3px;
    /* bottom: 0; */
    /* align-items: center; */
    /* justify-content: center; */
}

.image:hover .icon{
    opacity: 1;
}

.image{
    /* position: absolute; */
    /* opacity: 0; */
    z-index: 1;
}
.ant-tooltip-inner{
  display: none;
}
.ant-tooltip-arrow{
  display: none;
}