.container {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  gap: 1rem;
  background-color: #ffffff;
}

.UserData {
  padding: 0.8rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  border-bottom: 2px solid rgb(245, 243, 243);
}

.UserData > :nth-child(1) {
  width: 35px;
  height: 35px;
}
.UserData > :nth-child(1) > * {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.UserData > :nth-child(2) {
  font-size: 14px;
  font-weight: 600;
}

.UserData > :nth-child(3) {
  flex: 1;
  display: flex;
  justify-content: right;
  align-items: end;
}

.restDetails {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  background-color: #f3f6fa;
  padding: 1rem;

}

.restDetails > * {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.restDetails > * > :nth-child(1) {
  flex: 1 1 50%;
  font-size: 13px;
  font-weight: 600;
  color: "#00000";
}
.restDetails > * > :nth-child(2) {
  flex: 1 1 45%;
  font-weight: 500;
  font-size: 13px;
  color: grey;
}

.contacts {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.restDetails > *:nth-last-child(1) {
  display: flex;
  justify-content: right;
}
.restDetails > *:nth-last-child(1) > * {
  text-decoration: underline;
  display: flex;
  padding-right: 1rem;
  justify-content: right;
}
