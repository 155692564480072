@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.ParticipationRate-container{
    
  width: 576px;
  
  height: 352px;
  padding: 32px; /* Example padding */
  border-radius: 8px;
background: var(--Neutral-0, #FFF);
margin-top: 24px;
margin-right: 24px;


}
.ParticipationRate-container h2{
  color: var(--Neutral-950, #0D0D0D);
font-family: "Roboto";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.LabelListClass{
  font-family: 'Roboto';
}