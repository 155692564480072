.container{
    display: flex;
    flex-direction: column;
    height: 50vh;
    overflow-y: scroll;
}

.profile1{
    display: flex;
    flex-direction: column;
    margin-bottom:2rem;
    padding:0.5rem;
   
}

.profile{
    display: flex;
    flex-direction: row;
    width:50%;
    flex-wrap: nowrap;
    gap:1em;
    font-family: "poppins"; 
    margin-bottom:1rem ;   
   
}

.profile img{
    width:40px;
    height:40px;
    border-radius: 50%;
}

.profile1 span{
    color:#131212;
    font-size: 15px;
   text-align: justify;
   text-justify: inter-word;
    font-family: "poppins";
}

.profile1 p{
    font-size: 10px;
    margin-top: 0;
}
.profile > *:nth-child(1){
    flex: none;
   width: 40px;
   height: 40px;
}

.profile > *:nth-child(1) img{
    flex: none;
   width: 100%;
   height: 100%;
  border-radius: 50%;
}

.profile > *:nth-child(2){
    flex: 1 1 50%;
}
.employee{
    display: flex;
    flex-direction: column;
    font-family: 'poppins';
    color:#131212;
}

.employee h2{
    font-size:16px;
    font-weight:bold;
    color:black;
    font-family: "poppins";
}

.employee h3{
    color:#131212;
    font-weight: 200;
    font-size: 10px;
    font-weight: 600;
}

.imagePart{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap:1em;
    margin-bottom:2rem ;
}

.imagePart > *{
    flex:1 1 55%;
}

.imagePart img{
    width:150px;
    height:130px;
    border-radius: 4px;
}

.imagePart p{
    font-size: 10px;
}

.profile2{
    display: flex;
    flex-direction: column;
    width: 180px;
    padding:0.5rem;
}

.profile2 span{
    color:#131212;
    font-size: 15px;
    text-align: justify;
    text-justify: inter-word;
    font-family: "poppins";
    max-width:170px; 
}

.imageProf{
    display: flex;
    flex-direction: row;
    width:100%;
    flex-wrap: nowrap;
    gap:1em;
    font-family: "poppins";   
}

.imageProf img{
    width:40px;
    height:40px;
    border-radius: 50%;
}


.scroll::-webkit-scrollbar {
    width: 5px;          
  }
  

  
  .scroll::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;  
    border-radius: 20px;    

  }

.likeDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin:4rem 0 1rem 0rem;
    padding: 1rem 0 0 2rem;
    border-bottom: 1.3px solid #000000;
    border-top: 1.3px solid #000000;
    gap:1em;
}

.likeDiv > *:nth-child(1)
{
    flex:1 1 8%;  
}

.likeDiv > *:nth-child(2){
    flex:1 1 90%;
}

.likeDiv img{
    width:20px;
    height:20px;
}

.likeDiv p{ 
    color:#000000;
    font-size: 14px;
    font-weight: 300;
    font-family: "poppins";
}

.likeDiv p span{
    font-weight: bold;
}

.formControl{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    height: 10vh;
}

.formControl > *:nth-child(1){
    flex: 1 1 90%;  
}

.formControl > *:nth-child(2){
    flex:1 1 5%; 
}

.formControl > *:nth-child(3){
    flex:1 1 5%;
}

.formControl button{
    border:0;
    margin-bottom: 2.4rem;
    background-color:#f3f6fa;
    border:1px solid #f3f6fa;
    color:#8f87ff;
    font-family: 'poppins';
    font-weight: bold;
    font-size: 16px;   
}
.post{
    background-color:#f3f6fa;
    border:1px solid #f3f6fa;
    color:#8f87ff;
    padding: 0;
}

.formControl div{
    max-height: 50px;
    max-width:120px;
}

.formControl button:hover,
.formControl button:active{
    outline:none;
    border:1px solid#f3f6fa;
    background-color: #f3f6fa;
}

.formControl img{
    width:25px;
    height:25px;
}

.formControl textarea{
    background-color: #f3f6fa;
    border:1px solid #f3f6fa;
    font-family: 'poppins';
    font-weight:500;
    font-size: 14px;
    color:#131212;
    resize: none;
    padding: 10px;   
}

@media(max-width:700px)
{
  .houseModal{
            display: flex !important;
    flex-direction: column !important;
        gap: 2rem;
        height: 400px;
        overflow-y: auto;
        padding: 10px 10px 0px 0px;

    }

    .container{
   width:100%;
        display: flex;
        /* flex-direction: column; */
    }
    
    .profile1{
        margin-bottom:1.5rem;
        padding:0rem; 
    }
    
    .profile{
        width:100%;
        flex-wrap: nowrap;
        gap:0.6em;
        margin-bottom:0rem ;   
    }
    
    .profile img{
        width:40px;
        height:40px;
        border-radius: 50%;
    }
    
    .profile1 span{
        color:#131212;
        font-size: 15px;
       text-align: justify;
       text-justify: inter-word;
        font-family: "poppins";
    }
    
    .profile1 p{
        font-size: 10px;
        margin-top: 0;
    }
      
    .employee h2{
        font-size:14px;
        font-weight:600;
        color:black;
        font-family: "poppins";
        margin: 0;
    }
    
    .employee h3{
        color:#131212;
        font-weight: 200;
        font-size: 10px;
        font-weight: 600;
    }
    
    .imagePart{
        flex-direction: column;
        gap:0;
        margin-bottom:2rem ;
    }
    
    .imagePart > *{
        flex:1 1 100%;
    }
    
    .imagePart img{
        width:100%;
        height:130px;
        border-radius: 4px;
    }
    
    .imagePart p{
        font-size: 10px;
    }
    
    .profile2{
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        padding:0.5rem 0 0 0;
    }
    
    .profile2 span{
        font-size: 13px;
        width: 100%;
        max-width: none;
    }
    
    .imageProf{
        display: flex;
        flex-direction: row;
        width:100%;
        flex-wrap: nowrap;
        gap:0.5em;
        font-family: "poppins";   
    }
    
    .imageProf>:nth-child(1){
        width:40px;
        height:40px;
    }

    .imageProf>:nth-child(1) img{
        width:100%;
        height:100%;
        border-radius: 50%;
    }
    

}
@media(min-width:700px)
{
    .imageProf{
    display: flex;
    flex-direction: row;
    width:100%;
    flex-wrap: nowrap;
    gap:1em;
    font-family: "poppins";  
}
.houseModal>*:nth-child(1)
{
    flex: 1 1 50%;
}

.houseModal>*:nth-child(2)
{
    flex: 1 1 50%;
}
}

.houseModal{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}


