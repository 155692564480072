
  .text-color-class{
    color: black;
    /* display: flex;
    flex-direction: column; */
  }
  
  .LocationChart-container{
    min-width: 336px;
    
    height: 357px;
    padding: 32px; /* Example padding */
    border-radius: 8px;
  background: var(--Neutral-0, #FFF);
  margin-right: 24px;
  }
.LocationChart-container h2{
  color: var(--Neutral-950, #0D0D0D);
font-family: "Roboto";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
  
  .LocationPie{
    width: 208px;
    height: 256px;
  }
  .path.recharts-sector.LocationPie{
    width: 208px;
    height: 256px;
    margin-left: 48px;
  }