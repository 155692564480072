.container h1 {
  font: normal normal bold 20px/25px Poppins;
  margin-left: 10px;
}

/* .group_container {
  margin-top: 16px;
  height: 40px;
  font-weight: bold;
  display: flex;
  cursor: pointer;
  font-family: poppins;
  border: 2px solid black;
} */



/* .convo {
  display: flex;
  width: 100%;
  justify-content: center;
  border: none;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
} */
/* .grp {
  width: 100%;
  display: flex;
  font-weight: bold;
  border: none;
  border-left: 1px solid rgb(227, 225, 225);
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
} */

.convo:active,
.convo:focus {
  background-color: #c0e0fe;
}

.grp:active,
.grp:focus {
  background-color: #c0fedf;
}


/* singleallChatContainerLeft */

.chat_main {
  display: flex;
  gap: 10px;
  height: 100vh;
}

.chatBox {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  /* position: fixed; */
  width: 90%;
  height: 73vh;
  box-shadow: none;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: white;
  margin-top: 14px;
  /* border:1px solid rgba(0, 0, 0, 0.85) */
    /* border:2px solid red */
}

.message {
  font-weight: bold;
  /* margin-top: 170px;
  margin-left: 160px; */
  font: normal normal bold 26px/33px Poppins;
}


.chatDropDownContainer::-webkit-scrollbar {
  width: 3px;
}
.chatDropDownContainer::-webkit-scrollbar-thumb {
  background-color: silver;
}

.chatDropDownRecentChat {
  height: 30vh;
  overflow-y: scroll;
}
.chatDropDownRecentChat::-webkit-scrollbar {
  width: 3px;
}
.chatDropDownRecentChat::-webkit-scrollbar-thumb {
  background-color: white;
}

.ChatDrpDownStartNewChat {
  height: 30vh;
  overflow-y: scroll;
}
.ChatDrpDownStartNewChat::-webkit-scrollbar {
  width: 3px;
}
.ChatDrpDownStartNewChat::-webkit-scrollbar-thumb {
  background-color: white;
}
/* .searchdiv {
  display: flex;
  gap: 24px;
  margin-top: 16px;
  margin-right: 16px;
  align-items: center;
  justify-content: space-between;
} */

@media screen and (max-width: 700px) {
  .chatDropDownContainer {
width:97%;
  height: 60vh;
  background-color: white;
  border-top: 1px solid #6fc78f;
  border-bottom: 1px solid #6fc78f;
  overflow-y: scroll;
  box-shadow: 10px;
   position: fixed;
   /* border: 2px solid red; */
}
/* .scroll{
  border:2px solid blue
} */

    /* .chat_main{ */
      /* display: none; */
    /* } */

    .ChatDropDownCol{
    position: absolute;
    margin-top: 7vh;
    /* margin-left: 22px; */
  }
  .more {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .MessagesMob {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 7px;
  }
  .creategrpIconDiv {
    width: 28px;
    height: 28px;
    margin-left: 5px;
  }
  .searchDiv {
    width: 100%;
    border: 1px solid #9e9999;
    border-radius: 4px;
     display: flex;
           /* flex-direction: inherit; */
        align-items: center;
    /* opacity: 0.56; */
  }
  .searchdivCont {
    display: flex;
    width: 100vw;
    align-items: center;
    padding:7px;
  }
  /* .MobileRow {
    border: 2px solid blue;
  } */
  .MessagesHeading {
    display: none;
  }
  .backIcon {
    width: 15px;
    height: 15px;
  }
  .hide {
    display: none;
  }
  .ChatsList {
    display: none;
  }

  .ChatsRow {
    border: 1px solid rgb(228, 226, 226);
    width:100vw;
  }
  .group_container {
    width: 98vw;
    margin-top: 16px;
    height: 48px;
    font-weight: bold;
    display: flex;
    cursor: pointer;
    font-family: poppins;
    border-bottom: 1px solid #d2d0d0;
    padding: 5px;

  }

  .convo {
    display: flex;
    width: 50%;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .grp {
    width: 50%;
    display: flex;
    font-weight: bold;
    border: none;
    border-left: 1px solid rgb(227, 225, 225);
    font-size: 16px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .chatWindow {
    /* height: 100%;
    width: 100%; */
    display: none;
  }
  .chatWindowMobile {
    height: 100%;
    width: 100%;
  }
  .HeadingRow {
    gap: 8px;
  }
}
@media screen and (min-width: 700px) {
  .chatDropDownContainer {
  width: 26%;
  height: 60vh;
  background-color: white;
  /* border-top: 1px solid #6fc78f; */
  /* border-bottom: 1px solid #6fc78f; */
  overflow-y: scroll;
  box-shadow: 10px;
   position: fixed;
}
  .ChatDropDownCol{
    position: absolute;
    margin-top: 8vh;
  }
  .searchDiv {
    display: flex;
    width: 100%;
    margin-top: -16px;
    align-items: center;
        /* flex-direction: inherit; */

  }
  .creategrpIconDiv {
    width: 34px;
    height: 34px;
  }
  .HeadingRow {
    /* border: 2px solid green; */
    height: 10%;
  }
  .container {
    /* border: 2px solid red; */
    height: 88vh;
    /* overflow: auto; */
  }
  .ChatsRow {
    height: 90%;
    display: flex;
    /* border:1px solid #dfdcdcf2 ; */
    /* width:90%; */
    /* justify-content: space-between; */
  }
  .ChatPersons {
    /* border: 2px solid blue; */
    height: 100%;
    width: 26%;
    /* height: 70%; */
  }
  .backIcon {
    display: none;
  }
  .chatWindow {
    height: 100%;
    width: 64%;
    /* border: 2px solid red; */
  }
  .chatWindowMobile {
    display: none;
  }
  /* .ChatsList {
  } */
  /* .container {
    border: 2px solid gray;
  } */
  .searchdivCont {
    display: flex;
    gap: 24px;
    margin-top: 16px;
    margin-right: 16px;
    align-items: center;
    justify-content: space-between;
  }
  .group_container {
    
    margin-top: 16px;
    height: 40px;
    font-weight: bold;
    display: flex;
    cursor: pointer;
    font-family: poppins;
  }
  .convo {

    display: flex;
    width: 50%;
    justify-content: center;
    border: none;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .grp {
    width: 50%;
    display: flex;
    font-weight: bold;
    border: none;
    border-left: 1px solid rgb(227, 225, 225);
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
  }
}