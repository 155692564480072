.createGrpHeading{
    font: normal  bold 24px Poppins;

}
.grpName{
font:normal  16px Poppins;
margin-top: 10px;
}
.teamMemberName{
font:normal  16px Poppins;
margin-top: 12px;
}

.creategroupBtn{
    /* padding: 7px 12px 12px; */
    /* width: 9vw; */
    color:#ffffff ;
    font-size: 13px;
    font-family: "poppins";
    cursor: pointer;
    outline: none;
    border: 1px solid #6FC78F;
    background-color: #6FC78F;
    border-radius: 3px;
    height: 6vh;
    /* margin-right: 10px; */
}

