.EventOverviewContainer {
 
    width: 576px;
    
    height: 513px;
    padding: 32px; /* Example padding */
    border-radius: 8px;
  background: var(--Neutral-0, #FFF);
  margin-right: 24px;
  

  }
  
  .EventOverviewHeader {
    width: 512px;
    height: 38px;
  }
  .EventOverviewHeader h2{
    margin-bottom: 4px;
 
  color: var(--Neutral-950, #0D0D0D);
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .EventOverviewHeader p{
    margin-bottom: 16px;
    color: var(--Neutral-800, #303030);
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  