.scrollbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0;
}



.comment > *:nth-child(1) {
  flex: 1 1 15%;
}





.commentDiv input {
  width: 90%;
  border: none;
  outline: none;
  color: #000000;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  margin: 6px;
}

.upload {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;
  gap: 1em;
  margin-right: 1rem;
  margin-bottom: 0;
  padding-bottom: 0;
  align-items: right;
  justify-content: right;

  margin-left: 14rem;
  margin-top: 0.7rem;
  width: 24%;
}

.upload > * {
  flex: 1 1 50%;

}
.upload button {
  font-size: 14px;
  font-weight: 600;
  font-family: "poppins";
  padding: 0;
  height: 30px;
  background: none;
  border: 0;
}



.postbutton:hover {
  color: rgb(15, 101, 15);
}

.postButton :disabled {
  color: #000000;
  cursor: not-allowed;
}

@media screen and (max-width: 700px) {
  .buttonDiv button {
  width:18vw;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  border: 1px solid #e5e5e5;
  color: #b2b2b2;
  display: flex;
  justify-content: center;
 height: 100%;
}
  .buttonDiv {
    height: 5vh;
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
  .buttonDiv .sendButton {
  background-color: #6fc78f;
  border: 1px solid #6fc78f;
  color: #ffffff;
  width:18vw;
  font-size: 14px;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
}
  .comment > *:nth-child(2) {
  flex: 1 1 85%;
  max-width: 700px;
  height:15vh;
}
  /* .commentDiv > *:nth-child(2) {
border:2px solid blue
} */
  .postButton {
  color: green;
  cursor: pointer;
  width: 100%;
}
.inputSearch::placeholder {
font-size: 12px;
}
  .scrollbar{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .comment{
    display: flex;
    /* flex-wrap: nowrap; */
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    align-items: center;
     padding-bottom: 16px;

    
  }

  /* .comment > *:nth-child(1) {
    flex: 1 1 13%;
   
    margin-right: -1rem;

  
  } */
  .commentDiv{
    width: 45%;
    height: 100px;
    background-color: white;
    box-shadow: 0px 0px 8px #7e767614;
    border-radius: 7px;
  }
  /* .commentDiv > *:nth-child(2) {
    justify-content: right;
    align-items: right;
    margin-left: 11rem;
}

.commentDiv input {
  width: 90%;
  border: none;
  outline: none;
  color: #000000;
  padding-top: 0.5rem;
  margin: 6px;

} */
}
@media screen and (min-width: 700px) {
  .inputSearch::placeholder {
font-size: 13px;
}
  .buttonDiv button {
  font-family: "open sans";
  font-weight: bold;
  font-size: 14px;
  width: 158px;
  height: 47px;
  margin-right: 2rem;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: #b2b2b2;
  cursor: pointer;
}
  .buttonDiv {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
  .buttonDiv .sendButton {
  background-color: #6fc78f;
  border: 1px solid #6fc78f;
  color: #ffffff;
}
  .comment > *:nth-child(2) {
  /* flex: 1 1 85%; */
  max-width: 700px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
}
  .commentDiv > *:nth-child(2) {
  justify-content: right;
  align-items: right;

}
  .postButton {
  color: green;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.comment {
  display: flex;
  flex-direction: row;
  border: 1px solid #e6e6e6;
 border-radius: 4px;
  align-items: center;
  gap: 1em;

}
.commentDiv {
  width: 95%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 8px #7e767614;
  margin-right: 1.3rem;
  display: flex;
  flex-direction: column;
  border-radius: 7px;

}
}