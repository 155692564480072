.selectUser > * {
  max-height: 15vh;
  /* overflow-y: scroll; */
}



.grp-name {
  font: normal normal 600 16px/25px Poppins;
  white-space: nowrap;
}

.rc-virtual-list {
  height: auto;
  overflow: hidden;
}

.rc-virtual-list-holder > *:nth-child(1) > *:nth-child(2) {
  overflow: hidden;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #e7e0e0;
}
.img {
  width: 100px;
  padding: 1em;
}
@media screen and (max-width: 700px) {
  .creategrpBtn {
    padding: 3px 12px 12px;
    /* width: 10vw; */
    color: #ffffff;
    font-size: 13px;
    font-family: "poppins";
    cursor: pointer;
    outline: none;
    border: 1px solid #6fc78f;
    background-color: #6fc78f;
    border-radius: 3px;
    height: 4vh;
    margin-right: 10px;
}
  .creategrp-heading {
  font: normal normal bold 22px/33px "Poppins";
}
.createGrp-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}
.createGrp-containerone{
   display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  overflow: auto;
  /* margin-top: -30px; */
  height: 100%;
}
.createGrp-containerMob{
   display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  width:100%;
  height: 100%;
  padding: 16px;
}
}
@media screen and (min-width: 700px) {
  .creategrp-heading {
  font: normal normal bold 24px/35px "Poppins";
}
  .createGrp-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.creategrpBtn {
  padding: 7px 12px 12px;
  /* width: 10vw; */
  color: #ffffff;
  font-size: 13px;
  font-family: "poppins";
  cursor: pointer;
  outline: none;
  border: 1px solid #6fc78f;
  background-color: #6fc78f;
  border-radius: 3px;
  height: 6vh;
  margin-right: 10px;
}
}