.heartIcon {
  /* display: flex;
  flex-direction: row;
  flex-wrap: nowrap; */
  width: 100%;
  margin-top: 0.3rem;
  display: -webkit-inline-box;
  /* cursor: pointer; */
}
.heartIcon h3 {
  font-size: 14px;
  font-weight: 200;
  font-family: poppins;
  margin-left: 5px;
}

.heartIcon h3 span {
  font-weight: 600;
  color: #000000;
  
}

.heartIcon > *:nth-child(1) {
  flex: 1 1 30%;
}
.heartIcon > *:nth-child(2) {
  flex: 1 1 70%;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.imageRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: stretch;
}

.profileControl {
  align-items: left;
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 1em;
  margin-bottom: 0.5rem;
}

.profileControl > *:nth-child(1) {
  flex: 1 1 18%;
  margin-left: 0.5rem;
}
.profileControl > *:nth-child(2) {
  flex: 1 1 60%;
}

.profileDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profileDiv h2 {
  color: #131212;
  font-size: 12px;
  font-weight: 800;
  font-family: poppins;
  margin-bottom: 0;
}

.profileDiv h3 {
  color: #131212;
  font-size: 10px;
  font-weight: 600;
  font-family: poppins;
}
.tst{
  color: red;
}



@media screen and (max-width: 700px) {
  .player-container{
  border: 5px solid red !important;
}
  .imageDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0rem 1rem 0rem;
  cursor: pointer;
  width: 100% !important;
  gap: 1em;
  justify-content: space-evenly;
  padding-top: 15px;
}
.imageDiv .active {
  color: #000000;
  border-bottom: 1px solid black;
}
  .imageDiv span {
  color: #c1c1c1;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
  font-family: poppins;
}
   .ModalOverviewMobile {
    /* height: 90vh; */
    width: 100% !important;
    overflow-y: auto;
  }
  .NomCol {
    width: 100%;
    height: 90%;
    margin-bottom: 6px;
    overflow-y: auto;
  }
  .PostCol {
    width: 100%;
    height: fit-content;
    background-color: none;
    margin-right: 0;

  }
  .PostModalRow {
    width: 100%;
    height: 80vh;
    overflow: auto;
  }
 
  .scroll {
    overflow-y: scroll;
    width: auto;
    height: fit-content;
  }
 /* .imageControl {
    align-items: left;
    display: flex;
    flex-direction: column;
    width: 43vw;
    height: 45vh;
    cursor: pointer;
    align-items: baseline;
    border-radius: 4px;
   box-shadow:2px 2px 10px 2px #00000012;
   margin-left: 8px;
   padding: 3px;
  } */

  .imgsrcsingle {
    height: 140px;
    width: 100%;
    border-radius: 6px;
  }
}

@media only screen and (max-width: 700px) and (min-width: 500px) {
   .imageControl {
    align-items: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 45vh;
    cursor: pointer;
    align-items: baseline;
    border-radius: 4px;
   box-shadow:2px 2px 10px 2px #00000012;
   margin-left: 8px;
   padding: 3px;
  }

  .imageControlImages{
       align-items: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 25vh;
    cursor: pointer;
    align-items: baseline;
    border-radius: 4px;
   box-shadow:2px 2px 10px 2px #00000012;
   margin-left: 8px;
   padding: 3px;
  }
}
@media screen and (max-width: 500px) {
  .imageControl {
    align-items: left;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 45vh;
    cursor: pointer;
    align-items: baseline;
    border-radius: 4px;
   box-shadow:2px 2px 10px 2px #00000012;
   margin-left: 8px;
   padding: 3px;
  }
  .imageControlImages{
       align-items: left;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 25vh;
    cursor: pointer;
    align-items: baseline;
    border-radius: 4px;
   box-shadow:2px 2px 10px 2px #00000012;
   margin-left: 8px;
   padding: 3px;
  }
}
 

/* @media screen and (max-width: 398px) {
  .imageDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0rem 1rem 0rem;
  cursor: pointer;
  width: 97%;
  gap: 1em;
  justify-content: space-evenly;
  padding-top: 15px;
  border:2px solid red;
}} */

@media screen and (min-width: 700px) {
  .player-container{
  border: 5px solid red !important;
}
  .imageDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0rem 1rem 0rem;
  cursor: pointer;
  width: 100%;
  gap: 1em;
  justify-content: space-evenly;
}
  .imageDiv span {
  color: #c1c1c1;
  font-weight: bold;
  font-size: 16px;
  padding: 0;
  font-family: poppins;
}

.imageDiv .active {
  color: #000000;
  border-bottom: 2px solid black;
}
   .ModalOverview {
    position: relative;
  }
  .imgsrcsingle {
    height: 140px;
    width: 100%;
    border-radius: 6px;
  }
  .imageControl {
    align-items: left;
    display: flex;
    flex-direction: column;
    width: 14vw;
    height: 41vh;
    /* cursor: pointer; */
    align-items: baseline;
    border-radius: 4px;
   box-shadow:2px 2px 10px 2px #00000012;
   margin-left: 8px;
   padding: 3px;
  }
  .imageControlImages{
     align-items: left;
    display: flex;
    flex-direction: column;
    width: 14vw;
    height: 30vh;
    /* cursor: pointer; */
    align-items: baseline;
    border-radius: 4px;
   box-shadow:2px 2px 10px 2px #00000012;
   margin-left: 8px;
   padding: 3px;
  }
  .NomCol {
    width: 50%;
    height: 100vh;
    background-color: #f3f6fa;

  }
  .PostCol {
    width: 48%;
    height: 100%;
    background-color: #f3f6fa;
  }
  .PostModalRow {
    width: 100%;
    height: 70vh;
    gap:10px;
    display: flex;
    justify-content: space-around;
    overflow: auto;   
  }
.PostModalRow::-webkit-scrollbar {
  width: 5px; 
}
  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: 620px;
  }
  .imageRow {
    overflow-y: scroll;
    width: 100%;
    height: 620px;
    background-color:#f3f6fa;
    
  }
}


.profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile1 span {
  color: #131212;
  font-size: 15px;
  text-align: justify;
  text-justify: inter-word;
  font-family: "poppins";
}

.profile1 p {
  font-size: 10px;
  margin-top: 0;
}

.profile > *:nth-child(1) {
  flex: 1 1 16%;
}

.profile > *:nth-child(2) {
  flex: 1 1 50%;
}




.employee h3 {
  color: #131212;
  font-weight: 200;
  font-size: 10px;
  font-weight: 600;
}



.imagePart p {
  font-size: 10px;
}



.imageProf img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 20px;
}


.likeDiv > *:nth-child(1) {
  flex: 1 1 8%;
}

.likeDiv > *:nth-child(2) {
  flex: 1 1 90%;
}

.likeDiv img {
  width: 20px;
  height: 20px;
}

.likeDiv p {
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  font-family: "poppins";
}

.likeDiv p span {
  font-weight: bold;
}

.formControl {
  display: flex;
  flex-direction: row;
  /* flex-wrap: nowrap;
  justify-content: center; */
  align-items: center;
  gap:6px;
}

.formControl > *:nth-child(1) {
  flex: 1 1 90%;
}

.formControl > *:nth-child(2) {
  flex: 1 1 5%;
}

.formControl > *:nth-child(3) {
  flex: 1 1 5%;
}

.formControl button {
  border: 0;
  margin-bottom: 2.4rem;
  background-color: #f3f6fa;
  border: 1px solid #f3f6fa;
  color: #8f87ff;
  font-family: "poppins";
  font-weight: bold;
  font-size: 16px;
}
.post {
  background-color: #f3f6fa;
  border: 1px solid #f3f6fa;
  color: #8f87ff;
  padding: 0;
}

.formControl div {
  max-height: 50px;
  max-width: 120px;
}

.formControl button:hover,
.formControl button:active {
  outline: none;
  border: 1px solid#f3f6fa;
  background-color: #f3f6fa;
}

.formControl img {
  width: 25px;
  height: 25px;
}

.formControl textarea {
  background-color: #f3f6fa;
  border: 1px solid #f3f6fa;
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
  color: #131212;
  resize: none;
  padding: 10px;
}
@media screen and (max-width: 700px) {
  .employee {
  display: flex;
  flex-direction: row;
  font-family: "poppins";
  color: #131212;
  align-items: center;
  justify-content: space-evenly;
  width:100%;
  /* min-width: 18vw; */
}
    .profile2 {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    padding: 0.5rem;
     flex-wrap: nowrap;
  gap: 1em;
  font-family: "poppins";
  }
  .employee h2 {
  font-size: 14px;
  font-weight: bold;
  color: black;
  font-family: "poppins";
}
  /* .imageProf {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  gap: 1em;
  font-family: "poppins";
  border:2px solid blue
} */
  /* .imagePart img {
  width: 80px !important;
  height: 80px;
  border-radius: 4px;
} */
  
.likeDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 1rem 0 1rem 0rem;
  padding: 1rem 0 0 2rem;
  border-bottom: 1.3px solid #000000;
  border-top: 1.3px solid #000000;
  gap: 1em;
}
  .profile1 {
    margin-bottom: 2rem;
  }
  /* .profile {
  } */
  .imagePart {
    display: contents;
    margin-bottom: 2rem;
    width: 100%;
    justify-items: center;
  }


  .profile2 span {
    color: #131212;
    font-size: 15px;
    font-family: "poppins";
  }
}
@media screen and (min-width: 700px) {
  .container {
  display: flex;
  flex-direction: column;
}
  .employee {
  display: flex;
  flex-direction: column;
  font-family: "poppins";
  color: #131212;
}
    .profile2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
  }
  .employee h2 {
  font-size: 16px;
  font-weight: bold;
  color: black;
  font-family: "poppins";
}
  .imageProf {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  gap: 1em;
  font-family: "poppins";
}
  .imagePart img {
  width: 150px;
  height: 130px;
  border-radius: 4px;
}
.likeDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 4rem 0 1rem 0rem;
  padding: 1rem 0 0 2rem;
  border-bottom: 1.3px solid #000000;
  border-top: 1.3px solid #000000;
  gap: 1em;
}
  .profile2 span {
    color: #131212;
    font-size: 15px;
    text-align: justify;
    text-justify: inter-word;
    font-family: "poppins";
    max-width: 170px;
  }
  .profile1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding: 0.5rem;
  }
  .profile {
    display: flex;
    flex-direction: row;
    width: 55%;
    flex-wrap: nowrap;
    gap: 1em;
    font-family: "poppins";
    margin-bottom: 1rem;
  }
 
  .imagePart {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
    margin-bottom: 2rem;
  }
  .profile2 {
    display: flex;
    flex-direction: column;
    width: 180px;
    padding: 0.5rem;
  }
  .imagePart > * {
    flex: 1 1 55%;
  }
}
