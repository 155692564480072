@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

/* .location>*:nth-child(1)>*:nth-child(2){
  display: none; 
   padding: 10px;
} */
/* .ant-tooltip{
  display: none;
} */

.back_button_row {
  width: 100%;
  align-items: center;
  height: 3rem;
}
.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 20px;
}
.mainEventRow {
  height: 195px;
  margin: 20px 10px 40px 50px;
  display: flex;
  justify-content: space-between;
}
.mainEventRow2 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  gap: 1.5rem;
}
.eventCol {
  border: 2px solid #eeeeee;
  padding-top: 0.8rem;
  font-family: "poppins";
  font-size: 12px;
  height: 195px;
}
.eventImage {
  width: 200px;
  border-radius: 4px;
  border: 2px solid white;
  height: 147px;
  margin-top: 0.5rem;
}
.eventInfo {
  /* height: 147px; */
  width: 100%;
  margin-left: 3rem;
  /* border: 2px solid; */
}
.startDate {
  margin-top: 7px;
  display: flex;
  gap: 0.5rem;
}
.eventDetails {
  margin-top: auto;
  min-height: 40px;
  max-height: 60px;
  text-align: justify;
  overflow-y: scroll;
}
.nominationDate {
  margin-bottom: 1px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.3rem;
  max-width: 95%;
}
.greenDot {
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #6fc78f;
  margin-top: 4px;
  margin-left: 5px;
}
.eventType {
  font-family: "poppins";
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  margin-left: -4px;
}
.eventTypeInfo {
  display: flex;
  gap: 0.5rem;
  margin-top: 10px;
  max-width: 90%;
}
.mainWinnerDiv {
  box-shadow: 0px 2px 5px #00000036;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 5px;
}
.selectWinner {
  font-family: "poppins";
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
}
.creategroupBtn {
  padding: 15px 10px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-family: "poppins";
  cursor: pointer;
  outline: none;
  border: 1px solid #6fc78f;
  background-color: #6fc78f;
  border-radius: 4px;
  height: 53px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadBtn {
  background: none;
  font-family: "poppins";
  font-weight: bold;
  font-size: 16px;
  border: 0;
}
.eventDescription {
  width: 720px;
  font-size: 24px;
  font-family: "poppins";
  font-weight: 600;
}
.guidelinebtn {
  display: flex;
  margin-left: 100px;
  font-size: 24px;
  font-family: "poppins";
  font-weight: 600;
  gap: 5px;
  cursor: pointer;
}
.profile-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 50px 50px 40px 50px;
}
.profile-header h1 {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}
.profile-header img {
  width: 24px;
  height: 24px;
  margin: 0 1rem 0.5rem 0;
}
.userProfile {
  color: black;
}
.main {
  /* margin: 30px 30px 20px 10px; */
  z-index: 0;
}
.main .tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.tableHeader h1 {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}
.evenCol {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
}
.evenCol > * {
  flex: 1 1 33%;
}
.evenCol > *:nth-child(1) {
  flex: 1 1 30%;
}
.evenCol > *:nth-child(2) {
  flex: 1 1 60%;
}
.evenCol > * :nth-child(3) {
  flex: 1 1 10%;
}
.col {
  padding: 0;
}
.col img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.col h2 {
  font-size: 12px;
  font-family: "poppins";
  font-weight: 500;
  margin-bottom: 0;
  color: #000000;
}
.col p {
  font-size: 12px;
  font-weight: 400;
  font-family: "poppins";
  color: #000000;
}
.input {
  padding: 0.5rem 1rem;
  max-width: 20rem;
}
.colItem {
  font-family: "poppins";
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}
.status {
  display: flex;
  justify-content: right;
  margin: 1.5rem 3rem 1.5rem 0;
}
.status span {
  font-family: "poppins";
  font-size: 16px;
  font-weight: bolder;
  color: #222222;
}
.status button {
  width: 80px;
  height: 28px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  margin-right: 1rem;
}
.modal {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal h2 {
  font-size: 18px;
  font-family: "poppins";
  font-weight: bold;
  color: #ff6969;
}
.modal span {
  font-size: 16px;
  font-family: "poppins";
  font-weight: bold;
  color: #000000;
  margin-bottom: 0.9rem;
}
.confirm {
  display: flex;
  justify-content: space-between;
}
.confirm .button1 {
  margin-right: 2rem;
  font-size: 18px;
  font-family: "poppins";
  font-weight: bold;
  color: #000000;
  border: 1.5px solid #707070;
  padding-bottom: 0.8rem;
}
.change {
  color: white;
  font-family: "poppins";
  font-size: 16px;
  font-weight: bold;
}
.search {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.search > *:nth-child(1) {
  flex: 1 1 40%;
}
.search > *:nth-child(2) {
  flex: 1 1 60%;
}
.tableStyle {
  background-color: #f3f6fa;
  border: 1px solid #f3f6fa;
  font-family: "poppins";
  font-size: 12px;
}
.downloadButton {
  color: #000000;
}
.downloadButton:hover {
  color: blue;
}

.dateDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2em;
  margin-bottom: 0;
}
.dateDiv > * {
  margin-bottom: 0;
}
.dateDiv > * {
  flex: 1 1 50%;
}

.buttonDiv {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonDiv button {
font-family: "open sans";
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    height: 35px;
    margin-right: 2rem;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    color: #b2b2b2;
    cursor: pointer;
}

.buttonDiv .addButton {
  background-color: #6fc78f;
  border: 1px solid #6fc78f;
  color: #ffffff;
}
.guidlineUpload {
  height: 60px;
  background-color: #def4e6;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "poppins";
  font-size: 14px;
  justify-content: center;
  align-items: center;
}

.participatedHeading {
  margin-top: 1rem;
  font-family: poppins;
}


.locbtn:hover {
  color: green;
  border: 1px solid green;
}

.userProfileMob {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
}

.mobProfTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.mobProfTitle > *:nth-child(1) {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
}

.mobProfTitle > *:nth-child(1) > * {
  width: 100%;
  height: 100%;
}

.mobProfTitle > *:nth-child(2) {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.eventDetailsMob {
  border: 1.3px solid rgb(232, 224, 224);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
* {
  font-family: "poppins";
}
.eachEventMob {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.3px solid rgb(232, 224, 224);
  padding-bottom: 1rem;
  font-weight: 600;
}

.eventDetailsMob > *:nth-last-child(1) {
  border: 0;
}

.restEventMob {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  background-color: #f3f6fa;
  margin-bottom: 1rem;
  border-radius: 5px;
}
.restEventMob > * {
  gap: 1rem;
  display: flex;
  flex-direction: row;
}

.restEventMob > * > *:nth-child(1) {
  color: #000000;
  font-weight: 550;
  font-size: 13px;
}

.restEventMob > *:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.restEventMob > *:nth-child(2) > *:nth-child(2) {
  max-width: 16rem;
}

.userMobWrapper{
  flex: 1;
  padding: 1rem;
}

.userActiveness{
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  font-size: 15px;
 
  font-weight: 600;
}

.userMobWrapper *{
  font-family: "poppins";
}

.userMobWrapper h2{
  font-size: 1.3rem;
  font-weight: 700;
}

.userActiveness span{
  color: grey;
  height: 40;
  padding-bottom: 0.5rem;
}

.mobSearchUsers{
  border: 1px solid #9E9999;
  margin: 1rem 0 1rem 0;
  border-radius: 4px;
  
}



.UserData {
  padding: 0.8rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  border-bottom: 2px solid rgb(245, 243, 243);
}

.UserData > :nth-child(1) {
  width: 35px;
  height: 35px;
}
.UserData > :nth-child(1) > * {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.UserData > :nth-child(2) {
  font-size: 14px;
  font-weight: 600;
}

.UserData > :nth-child(3) {
  flex: 1;
  display: flex;
  justify-content: right;
  align-items: end;
}

.restDetails {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  background-color: #f3f6fa;
  padding: 1rem;
}

.restDetails > * {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.restDetails > * > :nth-child(1) {
  flex: 1 1 50%;
  font-size: 13px;
  font-weight: 600;
  color: "#00000";
}
.restDetails > * > :nth-child(2) {
  flex: 1 1 45%;
  font-weight: 500;
  font-size: 13px;
  color: grey;
}

.tabName{
    font-size: 1rem;
    font-weight: 650;
}

@media(max-width:700px)
{
    .dashboard {
    background-color: #ffffff;
     margin-bottom: 0;
    height: 90vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
   position: fixed;
   padding: 8px;
margin-top: 5px;
      }
    .profile-header {
        margin: 0;
      }
      .profile-header h1 {
        font-size: 16px;
        font-weight: 600;
      }
      .profile-header img {
        width: 20px;
        height: 20px;
        margin: 0 0.5rem 0.5rem 0;
      }

      .guidelinebtn {
        margin: 0.5rem 0 0 0;
        font-size: 16px;
        justify-content: center;
        background-color: #6fc78f;
        color: white;
        border-radius: 5px;
        padding: 0.2rem 0;
        font-weight: 600;

      }
      .mainEventRow {
        height:auto;
        margin: 0;
        flex-direction: column;
        margin-top: 1rem;
      }

      .eventCol {
        border: 2px solid #eeeeee;
        padding-top: 0;
        height: auto;
        padding: 0.5rem;
        background-color: #f3f6fa;
        margin-bottom: 1rem;
      }

      .mainEventRow2 {
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
      }

      .mainEventRow2>*{
        display: flex;
      }

      .eventImage {
        width: 100%;
        border-radius: 4px;
        border: 2px solid white;
        height: 147px;
        margin-top: 0;
      }

      .eventInfo {
        display: flex;
        height: auto;
        width: 100%;
        margin-left:0;
        flex-direction: column;
      }

      .startDate {
        margin-top: 0;
        gap: 0.5rem;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.7rem;
      }
      .startDate>*:nth-child(1){
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

      }
      .startDate>*:nth-child(1)>*{
        display: flex;
        gap: 1rem;

      }

      .eventDetails {
        margin-top: auto;
        min-height: auto;
        max-height: auto;
        text-align: justify;
        overflow-y: scroll;
      }

      .nominationDate {
        margin-bottom: 0;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.3rem;
        max-width: 100%;
      }
      .nominationDate>*{
        gap:1rem;
        display: flex;
      }

      .mainWinnerDiv {
        padding: 0.3rem 0;
        border-radius: 4px;
        box-shadow: 0px 1px 4px grey;
      }
      .selectWinner {
        font-size: 15px;
        font-weight: 650;
      }

      .creategroupBtn {
        padding: 0.3rem;
        font-size: 14px;
        font-weight: 600;
        height: auto;
        width: 180px;
      }

      .search {
        flex-direction: column;
        gap: 0.5rem;
      }
      .search > *:nth-child(1) {
        padding: 0;
      }
      .search > *:nth-child(1)>*{
        padding: 0;
        margin: 0;
        font-size: 1.3rem;
        font-weight: 600;
      }
      /* .search > *:nth-child(2) {
        border: 2px solid red;
        display: flex;
        flex-direction: column;
        justify-content: left;
        text-align: left;
      }

      .search > *:nth-child(2)>*{
        border: 2px solid red;
      } */
      .functionDiv {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items:start;
        flex-wrap: wrap;
        padding: 0;
        gap: 1rem;
        width: 50vw;
      }

      .functionDiv>*{
        width: 100%;
      }

      .functionDiv>*:nth-child(2){
        width: 100%;
      }

      .tabName{
        font-size: 0.9rem;
        font-weight: 500;
    }
}
@media(min-width:700px)
{

  .dashboard {
  background-color: #f3f6fa;
  padding: 1rem 1rem 0 1rem;
  height: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.functionDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: nowrap; */
  width:50vw;
}}