@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.TeamMemberAllocation-container {
  width: 696px;
  height: 383px;
  padding: 32px; /* Example padding */
  border-radius: 8px;
  background: var(--Neutral-0, #FFF);
  }
  
  .text-color-class{
    color: black;
  }
  
  .TeamMemberPie{
    display: flex;
    flex-direction: row;
    align-items: center;
    display: flex;
    width: 632px;
    gap: 32px;
    margin-top: 24px;
  }
  .TeamMemberPie1{
   
      
      width: 65%;

  }
  .TeamMemberHeading{
    width: 420px;
    height: 38px;

  }
  .TeamMemberHeading h2{
    margin-bottom: 4px;
    gap: 4px;
    color: var(--Neutral-950, #0D0D0D);
    font-family: " Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
  }
  .TeamMemberHeading p{
    color: var(--Neutral-800, #303030);
font-family: "Roboto";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 0px;
  }