@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.ParticipationInsightsContainer {
 
    width: 576px;
   
    height: 513px;
    padding: 32px; /* Example padding */
    border-radius: 8px;
  background: var(--Neutral-0, #FFF);
  margin-right: 24px;
  display: flex;
  flex-direction: column;

  }
.ParticipationHeader{
    
    height: 38px;
}
.ParticipationHeader h2{
    margin-bottom: 8px;
    color: var(--Neutral-950, #0D0D0D);
font-family: "Roboto";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;

}
.ParticipationHeader p{
  color: var(--Neutral-800, #303030);
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.overall-participation{
  margin-top: 32px;
  font-family: 'Roboto';

}
.overall-participation h3{
  font-size: 28px;
  margin-bottom: 0px;
  font-family: 'Roboto';
}
  
  