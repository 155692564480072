.GenderChart-container{
  min-width: 336px;
  
  height: 357px;
  padding: 32px; /* Example padding */
  border-radius: 8px;
background: var(--Neutral-0, #FFF);
margin-right: 24px;
}

.GenderPie{
  width: 208px;
  height: 256px;
  
}
.path.recharts-sector.GenderPie{
  width: 208px;
  height: 256px;
}
.GenderChart-container h2{
  color: var(--Neutral-950, #0D0D0D);
font-family: "Roboto";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}