@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

.dashboard {
  background-color: #f3f6fa;
}

.profile-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 2rem 2rem;
}

.profile-header h1 {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
}

.profile-header img {
  width: 24px;
  height: 24px;
  margin: 0 1rem 0.5rem 0;
}

.userProfile {
  color: black;
}

.main {
}
.main .tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;
}
.tableHeader h1 {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
}

.evenCol {
  display: flex;
  flex-wrap: nowrap;
}
.evenCol > * {
  flex: 1 1 33%;
}
.evenCol > *:nth-child(1) {
  flex: 1 1 30%;
}

.evenCol > *:nth-child(2) {
  flex: 1 1 60%;
}
.evenCol > * :nth-child(3) {
  flex: 1 1 10%;
}
.col {
  padding: 0;
}
.col img {
  width: 48px;
  height: 48px;
}

.col h2 {
  font-size: 14px;
  font-family: "poppins";
  font-weight: 500;
  margin-bottom: 0;
  color: #000000;
}
.col p {
  font-size: 10px;
  font-weight: 400;
  font-family: "poppins";
  color: #000000;
}
.input {
  padding: 0.5rem 1rem;
  max-width: 20rem;
}

.colItem {
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.status {
  display: flex;
  justify-content: right;
  margin: 1.5rem 3rem 1.5rem 0;
}

.status span {
  font-family: "poppins";
  font-size: 16px;
  font-weight: bolder;
  color: #222222;
}

.status button {
  width: 80px;
  height: 28px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  margin-right: 1rem;
}

.modal {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal h2 {
  font-size: 18px;
  font-family: "poppins";
  font-weight: bold;
  color: #ff6969;
}

.modal span {
  font-size: 16px;
  font-family: "poppins";
  font-weight: bold;
  color: #000000;
  margin-bottom: 0.9rem;
}
.confirm {
  display: flex;
  justify-content: space-between;
}
.confirm .button1 {
  margin-right: 2rem;
  font-size: 18px;
  font-family: "poppins";
  font-weight: bold;
  color: #000000;
  border: 1.5px solid #707070;
  padding-bottom: 0.8rem;
}

.change {
  color: white;
  font-family: "poppins";
  font-size: 16px;
  font-weight: bold;
}

.tableStyle {
  background-color: #f3f6fa;
  border: 1px solid #f3f6fa;
  font-family: "poppins";
  font-size: 14px;
}

.evenCol {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
}
.evenCol > * {
  flex: 1 1 33%;
}
.evenCol > *:nth-child(1) {
  flex: 1 1 30%;
}

.evenCol > *:nth-child(2) {
  flex: 1 1 60%;
}
.evenCol > * :nth-child(3) {
  flex: 1 1 10%;
}
.col {
  padding: 0;
}
.col img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.col h2 {
  font-size: 15px;
  font-family: "poppins";
  font-weight: 500;
  margin-bottom: 0;
  color: #000000;
}
.col p {
  font-size: 13px;
  font-weight: 400;
  font-family: "poppins";
  color: #000000;
}

.downloadButton {
  color: #000000;
}

.downloadButton:hover {
  color: blue;
}
.ant-drawer-bottom > .ant-drawer-content-wrapper {
    top: 16em;
    right: -1em;
    bottom: 0;
    width: 30%;
    height: 45vh;
    left: 14em;
}

.ant-drawer-content {
    width: 80%;
}
.ant-input:hover {
    border-color: whitesmoke;
    border-right-width: 1px;
}
.SearchCompo_inputSearch {
  align-items: center;
  background: #fff;
  background-image: url(https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-512.png);
  background-position: 10px calc(50% + 1px);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: 1px solid #bebebe;
  border-radius: 5px;
  
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-size: 13px;
  line-height: 1.5;
  padding: 0.5em 1em 0.5em 2.5em;
  width: 100%;

  &:focus {
    border-color: #bebebe;
    outline: none; 
    box-shadow: 0 0 5px #bebebe; 
  }
}
