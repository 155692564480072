.arrow-prev {
  font-size: 25px;
  margin-right: 0.5rem;
  cursor: pointer;
}
.arrow-next {
  margin-left: 0.8rem;
  font-size: 25px;
   cursor: pointer;
}
.card-content {
  color: #000000;
  width: 237px;
  height: 147px;
  display: flex;
  justify-content: center;
  /* margin: 5px 0px 0px 15px; */
  padding: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
}
.card-description {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "poppins";
  width: 200px;
  font-weight: 600;
  font-size: 18px;
}

.card-image-container {
  color: #000000;
  width: 99%;
  height: 147px;
  display: flex;
  margin-left: 1px;
  cursor: pointer;
}
.card-image {
  width: 100%;
  height: 100%;
}

.card-profile-container {
  display: flex;
  width: 220px;
  margin-left: -1rem;
}
.card-profile-img-container {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  /* border: 2px solid red; */
}
.card-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.card-profile-name-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
  font-family: "poppins";
  gap: "0.2rem";
  font-size: 12px;
  margin-top: 0.5rem;
}
.card-profile-name {
  width: 120px;
  font-family: "poppins";
  font-weight: bolder;
}
.card-profile-date {
  font-family: "poppins";
  font-weight: 500;
}
.heartIcon {
  width: 24px;
  height: 24px;
  margin-top: 5px;
}
.Scroll-container {
  margin-top: 20px;
  width: 100%;
}


  .cardItem {
    width: 220px;
    height: 220px;
    margin-right: 5px;
    border-radius: 8;
    cursor: default;
}


/* @media (max-width: 996px) {
  .cardItem {
    width: 180px;
    height: 180px;

    border: 2px solid red;
    display: flex;
  }

  .card-image-container {
    width: 120px;
    height: 60px;
    display: flex;
  }

  .card-profile-container {
    display: flex;
    width: 120px;
    margin-left: -1rem;
  }
  .card-profile-img-container {
    display: flex;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .card-profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .card-profile-name-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    font-family: "poppins";
    gap: "0.2rem";
    font-size: 12px;
    margin-top: 0.5rem;
  }
  .card-profile-name {
    width: 120px;
    font-family: "poppins";
    font-weight: bolder;
  }
  .card-profile-date {
    font-family: "poppins";
    font-weight: 500;
  }
  .heartIcon {
    width: 24px;
    height: 24px;
    margin-top: 5px;
  }
} */

@media screen and (max-width: 700px) {
  .Scroll-container {
    width: 100vw;
  }

}