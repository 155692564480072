@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.Analytics-container {
    background-color: #f3f6fa;
    margin-bottom: 0;
    padding: 10px 1rem 0 1rem;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.dashboard-header {
    
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 260px;
    height: 50px;
    margin-top: 24px;
    margin-left: 24px;
}

.dashboard-header h1 {
   
   
    
    color:  #0D0D0D;
    font-family: "Roboto";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
}

.dashboard-header p {
    
    color: #303030;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.dashboard-icon icons{
    color: var(--Blue-600, #ffffff);
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 10px 60px 10px 10px;

}
.main-content {
   
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    margin-left: 1rem;
   
}

.dashboard-contents{
    display: inline-flex;

    flex-direction: row;
    align-items: flex-start;
    margin-top: 1rem;
    margin-left: 1rem;

}
  

.analytics-box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.a-navbar {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-bottom: 2px solid #007bff;
}

.a-nav-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #777;
    cursor: pointer;
}

/* .a-nav-item.active {
    color: #007bff;
} */

/* .a-nav-item .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
} */

/* Underline for the active item*/
.a-nav-item.active::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #007bff;
    margin-top: 2px;
} 
    