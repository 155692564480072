.house-container {
  background: #f3f6fa;
  padding: 1.2rem;
  height: 100%;
  overflow-y: auto;
}

@media (max-width: 700px) {
  .house-container {
    padding: 0;
    margin-top: 5px;
    background-color: white;
    margin-bottom: 0;
    height: 90vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
  }
}
