.descard
{
    background: transparent url('img/prithvi.png') 0% 0% no-repeat padding-box;
opacity: 0.6;
margin-top: 50px;
}



.prithvicard

{
 margin-bottom: 25px;

}