
@media screen and (min-width: 700px) {
  .layout {
  height: 100vh;
  margin: 0;
  overflow-y: hidden;
}
}


@media screen and (max-width: 700px) {
  .layout {
  height: 100vh;
  margin: 0;
  overflow-y: hidden;
}
}