@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");

.container {
  background-color: #f3f6fa;
  margin-bottom: 0;
  padding: 10px 1rem 0 1rem;
  overflow-y: hidden;
}
.events-header {
  display: flex;
  margin: 10px 60px 10px 10px;
  justify-content: space-between;
  flex-direction: row;
}

.events-header h1 {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  font-family: "poppins";
  margin-left: 5px;
}

.events-header button {
  padding: 13px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-family: "poppins";
  cursor: pointer;
  outline: none;
  border: 1px solid #6fc78f;
  background-color: #6fc78f;
  border-radius: 4px;
}

.noEvents {
  display: flex;
  margin: 40px 60px 40px 0px;
  justify-content: center;
  height: 560px;
}
.noEvents h1 {
  color: #aa8fb5;
  font-size: 26px;
  font-weight: bold;
  font-family: "poppins";
}

.headerDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}

.header Div > *:nth-child(2) {
  flex: 1 1 100%;
  min-width: 10rem;
  
}

.modalSection {
  height: 10vh;
  width: 600px;
}


.modalSection > :nth-child(1) {
  display: flex;
  justify-content: center;
  background-color: #def4e6;
  cursor: pointer;
}
.modalSection > :nth-child(2) {
  overflow-y: scroll;
  max-height: 60px;
  background-color: #def4e6;
  width: 590px;
}
.modalSection > :nth-child(2)::-webkit-scrollbar {
  width: 5px;
}
.modalSection > :nth-child(2)::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}

.ant-upload-list-item-error
  .ant-upload-list-item-error
  .ant-upload-text-icon
  > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: black;
}

.ant-upload-list {
  border: 1px solid;
}

.guidlineUpload {
  height: 60px;
  background-color: #def4e6;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "poppins";
  font-size: 14px;
  justify-content: center;
  align-items: center;
}
.modalSectionDiv {
  color: #aeaeae;
}
.modalSectionDiv span {
  color: #6fc78f;
}

.modalSection span {
  font-family: "poppins";
  font-size: 14px;
}

.nameDiv {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.nameDiv > *:nth-child(2) {
  flex: 1 1 100%;
  min-width: 20rem;
}

.nameDiv input {
  display: flex;
  max-width: 674px;
}

.modalDiv {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 20px 20px;
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.modalDiv::-webkit-scrollbar {
  width: 4px;
}
.modalDiv::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  margin-left: 10px;
}
.modalDiv > * {
  margin-bottom: 0.6rem;
}
.dateDiv > * {
  margin-bottom: 0;
}

.buttonDiv {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonDiv button {
  font-family: "open sans";
  font-weight: bold;
  font-size: 14px;
  width: 158px;
  height: 47px;
  margin-right: 2rem;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: #b2b2b2;
  cursor: pointer;
}

.buttonDiv .addButton {
  background-color: #6fc78f;
  border: 1px solid #6fc78f;
  color: #ffffff;
}

.buttonDiv .sendButton {
  background-color: #6fc78f;
  border: 1px solid #6fc78f;
  color: #ffffff;
}

.modalDiv h1 {
  font-size: 22px;
  font-family: "poppins";
  font-weight: bold;
  color: #161616;
}

.modalDiv label {
  font-size: 12px;
  margin-bottom: 0;
  font-family: "poppins";
  font-weight: 500;
  color: #000000;
}

.textarea {
  width: 100%;
  height: 71px;
  border: 1px solid #e5e5e5;
  color: #ffffff;
}

.dateDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2em;
  margin-bottom: 0;
}
.dateDiv > * {
  flex: 1 1 50%;
}

.buttonDiv {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonDiv button {
  font-family: "open sans";
  font-weight: bold;
  font-size: 16px;
  width: 158px;
  height: 47px;
  margin-right: 2rem;
  background-color: white;
  border: 2px solid #e5e5e5;
  border-radius: 4px;
  color: #b2b2b2;
  cursor: pointer;
}

.buttonDiv .addButton {
  background-color: #6fc78f;
  border: 2px solid #6fc78f;
  color: #ffffff;
  cursor: pointer;
}
.buttonDiv .addButton:disabled,
.buttonDiv .addButton :hover {
  background-color: #6fc78f;
  border: 2px solid #6fc78f;
}



.datePicker {
  height: 100%;
  border: 1px solid #e5e5e5;
  color: #ffffff;
  outline: none;
}

.select {
  color: #ffffff;
}

.nameInput {
  height: 48px;
  width: 100%;
  border: 1px solid #e5e5e5;

  outline: none;
}

.select input {
  border: 1px solid #e5e5e5;
  padding: 0.5rem 1rem;
  border: 0;
}

.searchCompo{
  display: flex;
  margin: 10px 60px 10px 10px;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

/* style={{margin:"10px 60px 10px 10px",display:"flex",alignItems:'center',gap:'10px',justifyContent:'space-between'}} */

@media(max-width:700px)
{
  .guidlineUpload >*:nth-child(2) >*:nth-child(1){
    max-width:150px;
  }
   /* .guidlineUpload >*:nth-child(2) >*:nth-child(1) >*:nth-child(2){
    max-width:45px;
  } */
/* .ant-upload-list-item-name >* :nth-child(1) >*:nth-child(2){
      flex: auto;
    margin: 0;
    padding: 0 8px;
    width: 10px;
} */
.sendNow {
  color: #161616;
  font-size: 23px;
  font-family: "Times New Roman", Times, serif;
  /* padding:0.7rem 0 0.7rem 0.7rem; */
  font-weight: bold;
  border: 1px solid #707070;
  border-radius: 4px;
  height: 100%;
  width: 30vw;
  display: flex;
  justify-content: center;
  padding: 5px;
}
  /* .container {
    background-color: #ffffff;
    padding: 1rem;
  } */

.container{
     background-color: #ffffff;
    margin-bottom: 0;
    height: 90vh;
    width: 100%;
      margin-top: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
   position: fixed;
    /* background-color:#ffffff;
    height: 90vh;
    margin-bottom: 0;
    margin-top: 5px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0;
    position: fixed;
    width: 100%; */
}

  .events-header {
    display: flex;
    margin:0;
  }

  .events-header h1 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
  .events-header button {
    font-weight: 500;
    border-radius: 4px;
    width: 10rem;
    height: 2.5rem;
    text-align: center;
    font-size: 14px;
    padding: 0;
  }
  .searchCompo{
    width: 100%;
    margin: 0;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
  }
  .searchCompo>*:nth-child(2)
  {
    flex: 1 1 100%;
    margin-left: 1rem;
  }

  .searchCompo>*:nth-child(1)
  {
   border: 1.4px solid #9E9999;
   width: 100%;
   border-radius: 5px;
  }

  .noEvents {
    display: flex;
    margin: 0;
    justify-content: center;
    height: auto;
    margin-top: 1.5rem;
  }
  .noEvents h1 {
    font-size: 20px;
    font-weight: 600;
  }
  .modalDiv {
    width: 100%;
    margin:1rem 0 0 0;
    height: 520px;
    overflow-y: scroll;
    padding: 0;
  }

  .modalSection {
    height: 10vh;
    width: auto;
  }
  
  
  .modalSection > :nth-child(1) {
    display: flex;
    justify-content: center;
    background-color: #def4e6;
    cursor: pointer;
  }
  .modalSection > :nth-child(2) {
    overflow-y: scroll;
    max-height: 60px;
    background-color: #def4e6;
    width: auto;
  }
  .dateDiv {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 0;
  }
  .dateDiv > * {
    flex: 1 1 100%;
  }

  
.buttonDiv {
  width: 100%;
}


.buttonDiv>*>*>*>*{
  display: flex;
  justify-content: center;
  gap: 1rem;
}


.buttonDiv button {
  font-weight: 600;
  font-size: 0.8rem;
  width: 7rem;
  height: 2.2rem;
  margin-right:auto;
  border-radius: 3px;
}
}
@media(min-width:700px){
.sendNow {
  color: #161616;
  font-size: 30px;
  font-family: "Times New Roman", Times, serif;
  padding: 0.7rem 0 0.7rem 2.3rem;
  font-weight: bold;
  border: 1px solid #707070;
  border-radius: 4px;
}
}