.DepartmentChart-container {
  min-width: 576px;

  height: 352px;
  padding: 32px; /* Example padding */
  border-radius: 8px;
background: var(--Neutral-0, #FFF);
margin-top: 24px;
margin-right: 24px;
  }
.text-color-class{
    color: black;
  }
.labels{
    display: flex;
    flex-direction: row;
  }
.legend-text {
    font-size: 14px;
    
  }
.legend-color{
    width: 12px;
    height: 12px;
  }
.DepartmentChart-container h2{
  color: var(--Neutral-950, #0D0D0D);
font-family: "Roboto";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}