.tabs-menu .ant-tabs-tab {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    transition: all 0.3s;
    color: '#919191';
  }
  
  .tabs-menu .ant-tabs-tab:hover {
    background-color: #f5f5f5;
  }
  
  /* .tabs-menu .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
  }
  
  .tabs-menu .ant-tabs-ink-bar {
    background-color: #1890ff;
  } */
  
  .tab-pane {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    
  }
  
  .tab-span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
   

  }
  
  .tab-span svg {
    font-size: 18px;
    
  }
  
  .tab-span:hover {
    color: #1890ff;
    cursor: pointer;
  }
  .tabImage{
    width: 24px;
    height: 24px;
    
    
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 0px;
    color: #919191;
}

/* Custom scrollbar for Ant Design Tabs */
.ant-tabs-nav .ant-tabs-nav-wrap {
  scrollbar-color: #4ADA7B transparent; /* Custom scroller color */
  scrollbar-width: thin; /* Makes the scrollbar thinner */
}

/* For Webkit-based browsers like Chrome, Safari */
.ant-tabs-nav .ant-tabs-nav-wrap::-webkit-scrollbar {
  height: 6px; /* Adjust scrollbar height */
}

.ant-tabs-nav .ant-tabs-nav-wrap::-webkit-scrollbar-thumb {
  background-color: #4ADA7B; /* Scroller thumb color */
  border-radius: 3px; /* Optional: round corners */
}

.ant-tabs-nav .ant-tabs-nav-wrap::-webkit-scrollbar-track {
  background-color: transparent; /* Track background color */
}


  